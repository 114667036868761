import React from 'react';
import { IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';
import { SectionHeaderStyled } from './SectionHeader.styled';

const SectionHeader = ({ title, description, iconName, banner }) => {
    return (
        <SectionHeaderStyled>
            <h3>
                {title}
                {!!iconName && <PearlIcon iconName={iconName} size={IconSizes.LG} />}
            </h3>
            <p className="editDescription"> {description}</p>
            {!!banner && <div className="banner">{banner}</div>}
        </SectionHeaderStyled>
    );
};

export { SectionHeader };
