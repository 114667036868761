import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailabilityCalendarStyled } from './AvailabilityCalendar.styled';
import { CalendarDisplay, Spinner } from 'dbi-pearl-ui-kit';
import { add, startOfMonth, format } from 'date-fns';
import { useIsScreenLarge } from '../../../hooks/PearlMediaQuery';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import calendarStyles from 'react-date-range/dist/styles.css';
import calendarThemeStyles from 'react-date-range/dist/theme/default.css';

const AvailabilityCalendar = (props) => {
    const {
        webstore,
        sectionRef,
        sectionId,
        webstoreApi,
        iconName,
        banner,
        newWebstoreIdComing = false,
    } = props;
    const [availabilityDates, setAvailabilityDates] = useState([]);
    const [range, setRange] = useState(adjustDates(new Date()));
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    const apiDateFormat = 'yyyy-MM-dd';

    const isScreenLarge = useIsScreenLarge();

    function adjustDates(anchor) {
        const som = startOfMonth(anchor);
        const eom = add(som, { months: 2, days: -1 });
        return { som, eom };
    }

    function calendarChanged(anchorDate) {
        setLoading(true);
        setRange(adjustDates(anchorDate));
    }

    const getDateWithTimezoneOffset = (day) => {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const date = new Date(day);
        const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
        const tzDate = new Date(date.toLocaleString('en-US', { timeZone: tz }));
        const offset = utcDate.getTime() - tzDate.getTime();
        date.setTime(date.getTime() + offset);
        return date;
    };

    useEffect(async () => {
        const startDate = format(range.som, apiDateFormat);
        const endDate = format(range.eom, apiDateFormat);
        const webstoreDates = await webstoreApi.getWebstoreAvailability(
            webstore.Id,
            startDate,
            endDate,
            newWebstoreIdComing
        );
        if (webstoreDates) {
            const formattedDates = webstoreDates.map((day) => {
                day.date = getDateWithTimezoneOffset(day.availabilityDate);
                return day;
            });
            setAvailabilityDates(formattedDates);
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [range, webstore.Id]);

    const ariaLabels = {
        prevButton: t('WebstoreSections.AvailabilityCalendar.AriaLabels.PreviousButton'),
        nextButton: t('WebstoreSections.AvailabilityCalendar.AriaLabels.NextButton'),
    };

    return (
        <AvailabilityCalendarStyled ref={sectionRef} id={sectionId}>
            <style>
                {calendarStyles.default}
                {calendarThemeStyles.default}
            </style>
            <SectionHeader
                title={t('WebstoreSections.AvailabilityCalendar.Title')}
                iconName={iconName}
                banner={banner}
                description={t('WebstoreSections.AvailabilityCalendar.Description')}
            />
            <div className="calendarContainer" data-testid="calendarContainer">
                {loading && <Spinner />}
                <CalendarDisplay
                    months={!isScreenLarge ? 1 : 2}
                    dayStates={availabilityDates}
                    calendarChangeCallback={calendarChanged}
                    showDateSelectors={!isScreenLarge}
                    ariaLabels={ariaLabels}
                />
            </div>
            <div className="calendarLegendContainer" data-testid="calendarLegendContainer">
                <div className="available">
                    <div className="availableGraphic" />
                    <p className="p1">{t('WebstoreSections.AvailabilityCalendar.Available')}</p>
                </div>
                <div className="partial">
                    <div className="partialGraphic" />
                    <p className="p1">
                        {t('WebstoreSections.AvailabilityCalendar.PartiallyAvailable')}
                    </p>
                </div>
                <div className="unavailable">
                    <div className="unavailableGraphic" />
                    <p className="p1">{t('WebstoreSections.AvailabilityCalendar.Unavailable')}</p>
                </div>
            </div>
        </AvailabilityCalendarStyled>
    );
};

export { AvailabilityCalendar };
