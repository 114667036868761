/* eslint-disable no-console, no-unused-vars */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PearlForm from 'vendor/components/forms/PearlForm';
import { VendorAccountSetupStepLayout, VendorAccountSetupStepFooter } from 'framework';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/NewWebstoreSetupContext';
import { useTealium, buildWebstoreSetupStepPageData } from '../../../../Tealium';
import { useFormContext } from 'react-hook-form';
import { NewVendorWebstoreSetupHeader } from '../NewVendorWebstoreSetupHeader';
import { VendorCheckoutFormStyled } from './VendorCheckoutForm.styled';
import { CardElement } from '@stripe/react-stripe-js';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import CheckoutForm from 'vendor/components/Webstore/Checkout/CheckoutForm';
import { CheckoutOverviewSection } from 'vendor/components/Webstore/CheckoutOverview/CheckoutOverview';
import { useAuthUserContext } from 'contexts/AuthUserContext';
import { getValueByPlanName, formatPrice } from 'framework/constants/WebstorePlanTiers';
import { CheckoutFormSchemaWrapper } from 'vendor/components/Webstore/Checkout/CheckoutFormSchema';
import { StripeService } from 'lib/apis/StripeServiceNew';
import { StorageService } from 'lib/StorageService';
import { SiteLoader } from 'shared/components/SiteLoader/SiteLoader';
import { PromoCodeProvider } from 'vendor/components/Webstore/Checkout/PromoCodeContext';

const Footer = ({ isPay }) => {
    const { onBack, progress } = useWebstoreSetupWizard();
    const {
        // watch,
        formState: { isDirty },
    } = useFormContext();

    return (
        <VendorAccountSetupStepFooter
            progress={progress}
            handleOnBack={() => onBack()}
            showBack
            isPay={isPay}
        />
    );
};

const getTrialEnd = (days) => {
    const todaysDate = new Date();
    const trialEnd = new Date(todaysDate);
    trialEnd.setDate(todaysDate.getDate() + days);
    const dd = String(trialEnd.getDate()).padStart(2, '0');
    const mm = String(trialEnd.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = trialEnd.getFullYear();

    const formattedDate = `${mm}/${dd}/${yyyy}`;
    return formattedDate;
};

const VendorPayment = () => {
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [parsedStripeData, setParsedStripeData] = useState([]);
    const [planName, setPlanName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(null);
    const formName = 'StripeCheckout';
    const { authUser } = useAuthUserContext();
    const stripe = useStripe();
    const elements = useElements();
    const { link, view } = useTealium();
    const { webstore } = useWebstoreContext();
    const { onSubmit, currentStepCount, totalSteps } = useWebstoreSetupWizard();
    const { t } = useTranslation();
    const vendorId = webstore.VendorId;
    const storageService = StorageService();

    useEffect(() => {
        const fetchStripeData = async () => {
            setIsLoading(true);
            setLoadingError(null);
            try {
                const LocalStripeData = await storageService.get('stripeData');
                if (LocalStripeData) {
                    let stripeData = JSON.parse(LocalStripeData);
                    setParsedStripeData(stripeData);
                } else {
                    const stripeService = StripeService();
                    const response = await stripeService.getStripeProducts();
                    const result = await response?.data;
                    storageService.set('stripeData', JSON.stringify(result));
                    setParsedStripeData(result);
                }
            } catch (error) {
                setLoadingError('Error fetching Stripe data');
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchStripeData();
    }, []);

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const defaultValue = webstore.SelectedPlan?.toString() ?? null;
    const [priceId, setPriceId] = useState();
    const [planValue, setPlanValue] = useState();
    useEffect(() => {
        setPlanValue(getValueByPlanName(parsedStripeData, defaultValue, 'price'));
        setPriceId(getValueByPlanName(parsedStripeData, defaultValue, 'stripePriceId'));
        setPlanName(getValueByPlanName(parsedStripeData, defaultValue, 'tierName'));
    }, [parsedStripeData]);

    const [paymentError, setPaymentError] = useState('');
    const [paymentDataError, setPaymentDataError] = useState('');
    const formOptions = {
        mode: 'onChange',
        defaultValues: { SelectedPlan: defaultValue },
    };
    const [formBillingFrequency, setFormBillingFrequency] = useState(12);

    const handleBillingFrequencyChange = (frequency) => {
        setFormBillingFrequency(frequency);
    };
    const PaymentForm = () => {
        const { webstore } = useWebstoreContext();
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);
        useEffect(() => {
            const handleResize = () => setWindowWidth(window.innerWidth);

            // Add event listener for window resize
            window.addEventListener('resize', handleResize);

            // Clean up the event listener on component unmount
            return () => window.removeEventListener('resize', handleResize);
        }, []);
        const { watch } = useFormContext();

        useEffect(() => {
            webstore.CardHolderName = watch('CardholderName');
        }, [watch('CardholderName')]);

        useEffect(() => {
            if (formBillingFrequency === 12) {
                setPriceId(
                    getValueByPlanName(parsedStripeData, defaultValue, 'stripePriceId', 'year')
                );
            } else {
                setPriceId(
                    getValueByPlanName(parsedStripeData, defaultValue, 'stripePriceId', 'month')
                );
            }
        }, [formBillingFrequency]);

        return (
            <>
                <div className="errorContainer">
                    {paymentDataError && (
                        <div className="error-text" style={{ paddingLeft: '0px' }}>
                            {paymentDataError}
                        </div>
                    )}
                    {paymentError && (
                        <div className="error-text" style={{ paddingLeft: '0px' }}>
                            {paymentError}
                        </div>
                    )}
                </div>

                <div className="checkoutContainer">
                    {windowWidth > 815 ? (
                        <>
                            <PromoCodeProvider>
                                <CheckoutForm />
                                <CheckoutOverviewSection
                                    title={planName}
                                    planValue={Number(planValue)}
                                    subtotalTitle={t(`Forms.${formName}.Subtotal`)}
                                    totalTitle={t(`Forms.${formName}.Total`)}
                                    subscriptionSubtitle={t(
                                        `Forms.PlanTier.PlanInfo.${defaultValue}`
                                    )}
                                    taxLabel={t(`Forms.${formName}.taxLabel`)}
                                    paymentDisclaimer={t(`Forms.${formName}.PaymentDisclaimer`)}
                                    salesTax={0}
                                    savingsTitle={t(`Forms.${formName}.SavingsTitle`)}
                                    billingFrequency={formBillingFrequency}
                                    onBillingFrequencyChange={handleBillingFrequencyChange}
                                />
                            </PromoCodeProvider>
                        </>
                    ) : (
                        <>
                            <PromoCodeProvider>
                                <CheckoutOverviewSection
                                    title={planName}
                                    planValue={Number(planValue)}
                                    subtotalTitle={t(`Forms.${formName}.Subtotal`)}
                                    totalTitle={t(`Forms.${formName}.Total`)}
                                    subscriptionSubtitle={t(
                                        `Forms.PlanTier.PlanInfo.${defaultValue}`
                                    )}
                                    payDate={getTrialEnd(30)}
                                    taxLabel={t(`Forms.${formName}.taxLabel`)}
                                    salesTax={0}
                                    savingsTitle={t(`Forms.${formName}.SavingsTitle`)}
                                    paymentDisclaimer={t(`Forms.${formName}.PaymentDisclaimer`)}
                                    billingFrequency={formBillingFrequency}
                                    onBillingFrequencyChange={handleBillingFrequencyChange}
                                />
                                <CheckoutForm />
                            </PromoCodeProvider>
                        </>
                    )}
                </div>
            </>
        );
    };

    const handleOnSubmit = async (data) => {
        if (!stripe || !elements || !data?.CountryRegion || !data?.CardholderName) {
            return;
        }
        const couponCode = data?.PromoCode || undefined;
        data = {
            SelectedPlan: data.SelectedPlan,
            PaymentSuccess: paymentSuccess,
        };

        const card = elements.getElement(CardElement);
        const tealiumData = {
            event_name: 'setup-step-advance-click',
            setup_step_number: 1,
            setup_step_total_step_count: totalSteps,
            setup_step_name: t(`Forms.${formName}.StepName`),
            setup_step_form_data: data,
            setup_step_patch_data: data,
            webstore_id: webstore.Id,
        };

        link(tealiumData);
        if (!paymentSuccess) {
            try {
                // Step 1: Create a PaymentMethod
                const payData = await stripe.createPaymentMethod({
                    type: 'card',
                    card: card,
                    billing_details: {
                        name: webstore.CardHolderName,
                        email: authUser.Email,
                    },
                });
                const { paymentMethod } = payData;

                if (payData?.error) {
                    setPaymentDataError(`${payData?.error?.message}`);
                } else {
                    setPaymentDataError('');
                }
                const stripeService = StripeService();
                // Step 2: Create Subscription via Backend
                const response = await stripeService.createSubscription(
                    paymentMethod?.id,
                    webstore.CardHolderName,
                    priceId,
                    authUser.Email,
                    vendorId,
                    couponCode
                );
                const result = response?.data;
                if (result?.success) {
                    setPaymentSuccess(true);
                    setPaymentError('');
                    onSubmit(data);
                }

                // Step 3: Confirm PaymentIntent
                if (result.requiresAction) {
                    const { error, paymentIntent } = await stripe.confirmCardPayment(
                        result.clientSecret
                    );
                    if (error) {
                        setPaymentError(`Payment failed: ${error?.message || error}`);
                    } else if (paymentIntent?.status === 'succeeded') {
                        setPaymentSuccess(true);
                        setPaymentError('');
                        onSubmit(data);
                    }
                }
            } catch (error) {
                console.error('Payment error:', error);
                setPaymentError('Payment failed');
            }
        }
    };

    const CheckoutFormSchema = CheckoutFormSchemaWrapper();

    return (
        <PearlForm
            StyledForm={VendorCheckoutFormStyled}
            formName={formName}
            formOptions={formOptions}
            onSubmit={(data) => handleOnSubmit(data)}
            validationSchema={CheckoutFormSchema}
        >
            <VendorAccountSetupStepLayout
                header={
                    <NewVendorWebstoreSetupHeader
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps - 1}
                    />
                }
                formComponent={!parsedStripeData ? <SiteLoader /> : <PaymentForm />}
                footer={<Footer isPay={true} />}
            />
        </PearlForm>
    );
};

export { VendorPayment };
