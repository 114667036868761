import React, { createContext, useState, useContext } from 'react';

const PromoCodeContext = createContext({
    promoCodeDiscount: null,
    setPromoCodeDiscount: () => {},
});

export const usePromoCode = () => {
    return useContext(PromoCodeContext);
};

export const PromoCodeProvider = ({ children }) => {
    const [promoCodeDiscount, setPromoCodeDiscount] = useState(null);

    return (
        <PromoCodeContext.Provider value={{ promoCodeDiscount, setPromoCodeDiscount }}>
            {children}
        </PromoCodeContext.Provider>
    );
};
