import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCheckbox, ToggleSwitch } from 'dbi-pearl-ui-kit';
import { PearlError } from 'framework';
import { formFieldNames } from '../Functions/ServiceAreaForm.Functions';
import { ServiceTypeOptionsStyled } from './ServiceTypeOptions.styled';

const OnlineContent = () => {
    const { t } = useTranslation();

    return (
        <div className="option-content">
            <p className="option-title">{t('Forms.ServiceArea.Online')}</p>
        </div>
    );
};

const TravelsContent = () => {
    const { t } = useTranslation();

    return (
        <div className="option-content">
            <p className="option-title">{t('Forms.ServiceArea.Travel')}</p>
            <p className="option-subtitle p3">{t('Forms.ServiceArea.TravelSubtitle')}</p>
        </div>
    );
};

const ServiceTypeOptions = (props) => {
    const {
        onlineOptionAllowed,
        travelOptionAllowed,
        onlineStore,
        onlineStoreClickHandler,
        physicalStore,
        physicalStoreClickHandler,
        travel,
        travelsClickHandler,
    } = props;

    const { t } = useTranslation();

    return (
        <ServiceTypeOptionsStyled className="serviceOptions">
            <div className="store-options" role="group">
                {!!onlineOptionAllowed && (
                    <ContentCheckbox
                        fieldName={formFieldNames.OnlineStore}
                        data-testid="online-checkbox-label"
                        onClick={onlineStoreClickHandler}
                        isSelected={!!onlineStore}
                        content={<OnlineContent />}
                        className="service-option"
                    />
                )}

                {!!travelOptionAllowed && (
                    <ContentCheckbox
                        style={{ display: 'none' }}
                        fieldName={formFieldNames.Travels}
                        onClick={travelsClickHandler}
                        isSelected={travel}
                        content={<TravelsContent />}
                        className="service-option"
                    />
                )}
                <p className="option-question" style={{ marginTop: '0px' }}>
                    <b>{t('Forms.ServiceArea.PhysicalQuestion')}</b>
                </p>
                <div className="physicalStoreToggle">
                    <ToggleSwitch
                        fieldName={formFieldNames.PhysicalStore}
                        onChange={physicalStoreClickHandler}
                        checked={physicalStore}
                        name={formFieldNames.PhysicalStore}
                    ></ToggleSwitch>

                    <p style={{ paddingBottom: '3px' }}>
                        {(!!physicalStore && t('Forms.ServiceArea.Yes')) ||
                            t('Forms.ServiceArea.No')}
                    </p>
                </div>
            </div>
            <PearlError name="IsServiceTypeSelected" />
        </ServiceTypeOptionsStyled>
    );
};

export { ServiceTypeOptions };
