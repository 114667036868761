import styled from 'styled-components';
import XIcon from 'assets/X_Icon.svg';
const SocialLinksSectionStyled = styled.div`
    height: auto;

    .icon-twitter {
        content: url(${XIcon});
    }

    .XInput {
        font-size: 12px;
    }
`;

export { SocialLinksSectionStyled };
