/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Dropdown, ListView } from 'dbi-pearl-ui-kit';
import { PearlError } from 'framework';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStore } from '../../stores/Store';
import { FormRow } from 'framework';
import { SectionEditHeader } from './Webstore/SectionEdit/SectionEditHeader/SectionEditHeader';

const VendorCategorySelectDropdownStyled = styled.div`
    z-index: 1;
    .subcategoryContainer {
        padding-top: 40px;
    }
    .label {
        margin-bottom: 0px !important;
    }
    .catLabel {
        position: absolute;
        top: -10%;
        left: 1%;
        background: white;
        padding: 0 5px;
        z-index: 1;
        font-weight: 300;
        font-size: 10px;
        line-height: 15px;
        text-transform: none;
        letter-spacing: 0px;
    }
    .miniSubCatList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .list-items {
                display: grid;
                width: 100%;
                grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
                gap: 16px;

                & > div {
                    height: 49px;
                    padding: 0px 16px;
                    border-radius: 8px;
                    border-width: 2px;
                    box-shadow: 0px 2px 6px 0px rgba(19, 33, 34, 0.08);

                    & > label {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                    & > label > div {
                        display: flex;
                        flex-direction: column;
                    }

                    &:not(.is-checked) {
                        border-color: #e0e2e2;
                    }

                    .checkbox-label {
                        position: relative;
                        top: 0px;
                        text-transform: capitalize;
                        font-family: 'Objektiv Mk2 Bold';
                        font-size: 14px;
                        letter-spacing: 0px;
                        margin-left: 0px;
                    }
                }
            }
            .check {
                display: none;
            }
        }
    }
`;

const VendorCategorySelectDropdown = (props) => {
    const { t } = useTranslation();
    const { mini } = props;
    const { getValues, setValue, clearErrors, watch } = useFormContext();
    const { appData } = useStore();
    const { categoryList } = appData;
    const subCategory = watch('VendorSubCategoryIds') || [];
    const category = watch('VendorCategoryId') || [];
    const [prevCat, setPrevCat] = useState(category || '');

    const [categoryListItems] = useState(
        categoryList.map((x) => {
            return {
                id: x.id,
                label: x.name,
                imageUrl: x.image?.url,
                category: x,
                subCategories: x.subCategories.map((y) => ({
                    id: y.id,
                    label: y.name,
                    imageUrl: null,
                    subCategory: y,
                })),
            };
        })
    );

    const formCategoryId = getValues('VendorCategoryId');
    const [selectedCategory, setSelectedCategory] = useState(
        // eslint-disable-next-line eqeqeq
        categoryListItems.find((x) => x.id == formCategoryId)
    );
    const [currentCategorySubcategories, setCurrentCategorySubcategories] = useState(
        selectedCategory?.subCategories
    );

    const handleCategoryChange = (e) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }
    };
    useEffect(() => {
        const formCategoryId2 = watch('VendorCategoryId');
        if (Number(formCategoryId2) !== Number(prevCat) || formCategoryId2 !== null) {
            clearErrors('VendorCategoryId');
            const selectedCat = categoryListItems.find(
                (x) => Number(x.id) === Number(formCategoryId2)
            );
            setSelectedCategory(selectedCat || null);
            setPrevCat(selectedCat?.id || '');
            setCurrentCategorySubcategories(selectedCat?.subCategories || []);
            if (Number(formCategoryId2) !== Number(prevCat)) {
                setValue('VendorSubCategoryIds', []);
                clearErrors('VendorSubCategoryIds');
            }
        }
    }, [watch('VendorCategoryId')]);

    useEffect(() => {
        if (subCategory.length > 0) {
            clearErrors('VendorSubCategoryIds');
        }
    }, [subCategory]);
    const fullSize = (
        <div>
            {categoryListItems && (
                <div key="CategoryList">
                    <ListView
                        formFieldName="VendorCategoryId"
                        label={t('Forms.Labels.SelectCategory')}
                        multiSelect={false}
                        listItems={categoryListItems}
                        onChange={handleCategoryChange}
                    ></ListView>
                    <PearlError name="VendorCategoryId"></PearlError>
                </div>
            )}
            {selectedCategory && (
                <div className="subcategoryContainer" key="SubCategory List">
                    <ListView
                        formFieldName="VendorSubCategoryIds"
                        label={t('Forms.Labels.SelectSubCategory')}
                        multiSelect={true}
                        listItems={selectedCategory.subCategories}
                    ></ListView>
                    <PearlError name="VendorSubCategoryIds"></PearlError>
                </div>
            )}
        </div>
    );

    const miniSize = (
        <div>
            {categoryListItems && (
                <FormRow
                    columns={[
                        <div key={0} style={{ position: 'relative' }}>
                            {!selectedCategory && (
                                <span className="label catLabel">
                                    {t('Forms.BusinessDetails.SelectCategoryText')}
                                </span>
                            )}
                            <div key="CategoryList">
                                <Dropdown
                                    formFieldName="VendorCategoryId"
                                    providedLabel={t('Forms.Labels.SelectCategory')}
                                    listItems={categoryListItems}
                                    onClick={(checked) => handleCategoryChange(checked)}
                                ></Dropdown>
                                <PearlError name="VendorCategoryId"></PearlError>
                            </div>
                        </div>,
                    ]}
                ></FormRow>
            )}
            {selectedCategory && (
                <FormRow
                    columns={[
                        <div key={0} style={{ position: 'relative' }}>
                            <SectionEditHeader
                                title={t('Forms.Labels.SelectSubCategories', {
                                    subcat: selectedCategory.label,
                                })}
                                description={t('Forms.EditBasics.SubCatDescription')}
                            ></SectionEditHeader>
                            <div key="SubCategory List" className="miniSubCatList">
                                <ListView
                                    formFieldName="VendorSubCategoryIds"
                                    multiSelect={true}
                                    listItems={currentCategorySubcategories}
                                ></ListView>
                                <PearlError name="VendorSubCategoryIds"></PearlError>
                            </div>
                        </div>,
                    ]}
                ></FormRow>
            )}
        </div>
    );

    return (
        <VendorCategorySelectDropdownStyled>
            {!mini && fullSize}
            {!!mini && miniSize}
        </VendorCategorySelectDropdownStyled>
    );
};
export { VendorCategorySelectDropdown };
