import styled from 'styled-components';

const AvailabilityCalendarSectionEditStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 24px 48px 48px 48px;
    box-sizing: border-box;
    .section-header {
        & > h2 {
            text-transform: none;
        }
    }

    @media (min-width: 1350px) {
        .calendar-edit-section {
            margin-left: 20vw;
        }
    }

    main {
        display: flex;
        flex-direction: row !important;
        justify-content: center;
        background-color: ${(props) => props.theme.colors.background.container};
    }

    .back-button {
        label {
            align-text: center;
            border: unset;
            padding-bottom: 0;
        }
    }

    .rdrMonthAndYearWrapper {
        padding-top: 0;
    }

    .rdrWeekDays {
        margin-bottom: 24px;
    }

    @media (max-width: 974px) {
        .rdrMonth {
            padding-left: 0;
        }
    }

    .subtitleMargin {
        margin-bottom: 24px;
    }

    .statusContainer {
        box-sizing: border-box;
        border: 1px solid ${(props) => props.theme.colors.foreground.border};
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);
        margin-left: 32px;
        width: 432px;
        padding: 40px;

        .formLabel {
            color: ${(props) => props.theme.colors.primary.base};
            font-weight: bold;
            margin-bottom: 16px;
        }

        h3 {
            text-transform: none;
            margin: 0;
        }

        .section {
            display: flex;
            margin-bottom: 24px;
            flex-direction: column;
        }

        .statusButtons {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .box {
                border: 1px solid #e0e2e2;
                display: flex;
                cursor: pointer;
                min-height: 100%;
                align-items: center;
            }

            .boxLeftContent {
                width: 24px;
                height: 24px;
                margin: 13px;
                border: 0.7px solid #132122;
                border-radius: 50%;
                display: flex;

                .statusIndicator {
                    height: 100%;
                    width: 100%;
                    border-radius: 100%;
                }

                .StatusUnavailable {
                    background: linear-gradient(
                        -45deg,
                        #e0e2e2,
                        #e0e2e2 49%,
                        #424d4e 49%,
                        #424d4e 51%,
                        #e0e2e2 51%
                    );
                }

                .StatusPartial {
                    background: linear-gradient(
                        -45deg,
                        #e0e2e2,
                        #e0e2e2 49%,
                        #424d4e 49%,
                        #424d4e 51%,
                        #ffffff 51%
                    );
                }
            }

            .boxMiddleContent {
                display: flex;
                flex-direction: column;
                justify-content: center;

                p {
                    font-weight: bold;
                }
            }

            .boxRightContent {
                margin: 13px;
                color: ${(props) => props.theme.colors.primary.base};
                text-align: center;
                font-size: 18px;
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
            }

            .boxRow {
                display: flex;
                column-gap: 25px;
                row-gap: 24px;
                flex-wrap: wrap;
            }

            .boxInputWrapper {
                flex: 1 1 0;
            }

            .boxInputWrapper input {
                position: fixed;
                opacity: 0;
                pointer-events: auto;
            }

            input:checked + label .box {
                border: 1px solid #132122;
            }

            input:checked + label .box .boxRightContent::after {
                font-family: 'pearl-icons' !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                content: '\\e914';
            }
        }
    }

    @media (max-width: 815px) {
        padding: 0;

        .section-header {
            padding: 16px;
        }

        .statusContainer {
            width: 90%;
            margin-left: 0px;
        }
    }

    .editButton {
        position: sticky;
        height: 60px;
        bottom: 60px;
        z-index: 2;
    }
    #saveButton,
    #editButton {
        width: 100%;
    }
`;

export { AvailabilityCalendarSectionEditStyled };
