import React, { useEffect } from 'react';
import { vendorBusinessDetailsFormSchemaWrapper } from 'vendor/constants/schema/VendorBusinessDetailsFormSchemaNew';
import { useTranslation } from 'react-i18next';
import { VendorBusinessDetailsFormStyledNew } from './VendorBusinessDetailsFormNew.styled';
import { TextInput } from 'dbi-pearl-ui-kit';
import PearlForm from 'vendor/components/forms/PearlForm';
import {
    FormRow,
    PearlError,
    VendorAccountSetupStepLayout,
    VendorAccountSetupStepFooter,
} from 'framework';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/NewWebstoreSetupContext';
import { useTealium, buildWebstoreSetupStepPageData } from '../../../../Tealium';
import { useVendorContext } from '../../../contexts/VendorContext';
import { NewVendorWebstoreSetupHeader } from '../NewVendorWebstoreSetupHeader';
import { AddressAutoComplete } from 'vendor/components/AddressAutoComplete/AddressAutoCompleteNew';
import { formFieldNames } from 'framework/lib/WebstoreAddressService/WebstoreAddressService';
import { WebstoreAddressService } from 'framework';
import { vendorBusinessAddressFormSchema } from 'vendor/constants/schema/VendorBusinessAddressFormSchema';
import { mergeSchemas } from 'vendor/components/Webstore/WebstoreHelpers/WebstoreHelpers';
import { SocialLinksSection } from 'vendor/components/Webstore/SocialLinksSection/SocialLinksSectionNew';
const formName = 'BusinessInformation';

const Footer = () => {
    const { progress } = useWebstoreSetupWizard();

    return <VendorAccountSetupStepFooter progress={progress} showBack={false} />;
};

const DetailsForm = () => {
    const { t } = useTranslation();

    return (
        <div className="formComponent detailsContainer">
            <div>
                <p
                    className="label"
                    style={{
                        fontFamily: 'Objektiv Mk2 Bold',
                        fontSize: '12px',
                        lineHeight: '18px',
                    }}
                >
                    {t(`Forms.${formName}.InfoTitle`)}
                </p>
                <FormRow
                    columns={[
                        <div key={0}>
                            <TextInput
                                formFieldName="WebstoreName"
                                placeholder={t(`Forms.${formName}.WebstoreName`)}
                            />
                            <PearlError name="WebstoreName" />
                        </div>,
                        <div key={1}>
                            <SocialLinksSection />
                        </div>,
                    ]}
                />
            </div>
            <p
                className="label"
                style={{
                    fontFamily: 'Objektiv Mk2 Bold',
                    fontSize: '12px',
                    lineHeight: '18px',
                    marginTop: '24px',
                }}
            >
                {t(`Forms.${formName}.AddressTitle`)}
            </p>
            <AddressAutoComplete
                addressLine1Label={t(`Forms.${formName}.AddressLine1`)}
                cityLabel={t(`Forms.${formName}.AddressCity`)}
                stateLabel={t(`Forms.${formName}.AddressState`)}
                zipcodeLabel={t(`Forms.${formName}.AddressZipcode`)}
            ></AddressAutoComplete>
        </div>
    );
};

const VendorBusinessDetailsFormNew = () => {
    const { getBusinessAddress, createNewBusinessAddress } = WebstoreAddressService;
    const { link, view } = useTealium();
    const { vendor } = useVendorContext();
    const { webstore } = useWebstoreContext();
    const { onSubmit, currentStepCount, totalSteps } = useWebstoreSetupWizard();

    const { t } = useTranslation();

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );
        view(tealiumData);
    }, []);

    const businessAddress = getBusinessAddress(webstore);
    const vendorBusinessDetailsFormSchema = vendorBusinessDetailsFormSchemaWrapper();
    const schemas = [vendorBusinessDetailsFormSchema, vendorBusinessAddressFormSchema];

    const mergedSchemas = mergeSchemas(schemas);

    const formOptions = {
        mode: 'onChange',
        defaultValues: {
            WebstoreName: webstore.WebstoreName,
            [formFieldNames.BusinessAddressStreet1]: businessAddress?.address1,
            [formFieldNames.BusinessAddressCity]: businessAddress?.city,
            [formFieldNames.BusinessAddressState]: businessAddress?.state,
            [formFieldNames.BusinessAddressZipcode]: businessAddress?.zipCode,
            Website: webstore.Website,
            [formFieldNames.DisplayStoreAddress]: false,
            [formFieldNames.IsServiceTypeSelected]: true,
            [formFieldNames.ServiceAddressIsBusinessAddress]: false,
            [formFieldNames.PhysicalStore]: false,
            [formFieldNames.Travels]: true,
            [formFieldNames.TravelOption]: 'locally',
        },
    };
    const handleAddress = async (data) => {
        const updatedAddress = {
            address1: data[formFieldNames.BusinessAddressStreet1],
            city: data[formFieldNames.BusinessAddressCity],
            state: data[formFieldNames.BusinessAddressState],
            zipCode: data[formFieldNames.BusinessAddressZipcode],
            addressType: 'business',
        };

        let businessAddress = getBusinessAddress(webstore);

        if (businessAddress) {
            Object.keys(updatedAddress).forEach((key) => {
                businessAddress[key] = updatedAddress[key];
            });
        } else {
            businessAddress = {
                ...createNewBusinessAddress(),
                ...updatedAddress,
            };
            webstore.Addresses.push(businessAddress);
        }
    };
    const handleSubmit = async (data) => {
        await handleAddress(data);
        const tealiumData = {
            event_name: 'setup-step-advance-click',
            setup_step_number: 1,
            setup_step_total_step_count: totalSteps,
            setup_step_name: t(`Forms.${formName}.StepName`),
            setup_step_form_data: data,
            setup_step_patch_data: data,
            webstore_id: webstore.Id,
        };

        link(tealiumData);
        onSubmit(data);
    };

    return (
        <PearlForm
            StyledForm={VendorBusinessDetailsFormStyledNew}
            formName={formName}
            formOptions={formOptions}
            validationSchema={mergedSchemas}
            onSubmit={handleSubmit}
        >
            <VendorAccountSetupStepLayout
                header={
                    <NewVendorWebstoreSetupHeader
                        stepName={formName}
                        currentStepCount={currentStepCount - 1}
                        totalSteps={totalSteps - 1}
                    />
                }
                formComponent={<DetailsForm vendor={vendor} webstore={webstore} />}
                footer={<Footer />}
            />
        </PearlForm>
    );
};

export { VendorBusinessDetailsFormNew };
