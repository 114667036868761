import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsScreenLarge } from '../../../hooks/PearlMediaQuery';
import { WebstorePackage, WebstorePackageSizes } from '../WebstorePackage/WebstorePackage';
import { WebstorePackagesStyled } from './WebstorePackages.styled';
import { SectionHeader } from '../SectionHeader/SectionHeader';
const WebstoreSectionContentSlider = lazy(() =>
    import('../WebstoreSectionContentSlider/WebstoreSectionContentSlider')
);

const WebstorePackages = (props) => {
    const {
        webstore,
        sectionRef,
        sectionId,
        onDetailsClick,
        iconName,
        banner,
        showFlaggedOverlay,
    } = props;
    const { t } = useTranslation();

    const isScreenLarge = useIsScreenLarge();

    const packages =
        webstore.Packages?.map((pkg) => (
            <WebstorePackage
                key={pkg.id}
                pkg={pkg}
                size={WebstorePackageSizes.THUMB}
                onDetailsClick={onDetailsClick}
                showFlaggedOverlay={showFlaggedOverlay}
            />
        )) || [];

    return (
        <WebstorePackagesStyled ref={sectionRef} id={sectionId}>
            <SectionHeader
                title={t('WebstoreSections.Packages.Title')}
                iconName={iconName}
                banner={banner}
                description={t('WebstoreSections.Packages.Description')}
            />
            <div className="packages-container">
                {!isScreenLarge ? <WebstoreSectionContentSlider slides={packages} /> : packages}
            </div>
        </WebstorePackagesStyled>
    );
};

export { WebstorePackages };
