import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const PhysicalLocationOptionsStyled = styled.div`
    .ServiceAddress-container {
        width: 100%;
    }
    .serviceContainer {
        display: flex;
    }
    .toggle-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        .MuiFormControlLabel-root {
            margin-right: 0px;
        }
        & > p {
            margin: 0px 0px 0px -12px;
            line-height: 24px !important;
        }
    }
    .serviceAddressOuter {
        margin-right: 16px;
    }
    .serviceRow {
        width: 70%;
        margin-top: 7px;
    }
    .displayRow {
        margin-top: 2px;
    }
    .displayToggleService {
        display: flex;
        align-items: center;
        margin: 7px 0px 0px 0px;
        flex-direction: row;
        gap: 8px;
        padding: 0px;

        .MuiFormControlLabel-root {
            margin-right: 0px;
        }
    }
    .toggle-subtitle2 {
        padding-top: 0px !important;
        font-size: 16px;
        margin-left: -5px;
        line-height: 24px !important;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .displayToggle {
            flex-direction: row;
        }
        .serviceContainer {
            display: flex;
            flex-direction: column;
        }
        .serviceRow {
            width: 100%;
        }
        .displayToggleService {
            margin-top: 2px;
        }

        .serviceAddressOuter {
            margin-right: 0px;
        }
    }

    ${(props) => props.theme.pearlBreaks.up(BreakpointSizes.MD)} {
        .serviceContainer {
            align-items: center;
        }
    }
`;

export { PhysicalLocationOptionsStyled };
