import React, { useEffect } from 'react';
import { CapacitySectionStyled } from './CapacitySection.styled';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'dbi-pearl-ui-kit';
import { PearlError } from 'framework';
import { useFormContext } from 'react-hook-form';

function CapacitySection() {
    const { t } = useTranslation();
    const { watch, clearErrors } = useFormContext();

    const capacity = watch('Capacity');
    useEffect(() => {
        if (capacity) {
            clearErrors('Capacity');
        }
    }, [capacity]);

    return (
        <CapacitySectionStyled>
            <TextInput
                formFieldName="Capacity"
                providedLabel={t(`Forms.VenueCapacity.MaxGuestCapacity`)}
            />
            <PearlError name="Capacity" />
        </CapacitySectionStyled>
    );
}

export { CapacitySection };
