// got tired of putting this in random files during debugging
// shouldn't really have any references, but keeping here for
// future convenience
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const ensureAsync = (callback) => {
    if (callback.constructor.name === 'AsyncFunction') {
        return callback();
    }

    return new Promise((resolve, reject) => {
        try {
            const val = callback();
            resolve(val);
        } catch (e) {
            reject(e);
        }
    });
};

const maskEmail = (email) => {
    const [localPart, domain] = email.split('@');
    if (localPart.length > 4) {
        const maskedLocalPart = localPart.slice(0, 4) + '*'.repeat(localPart.length - 4);
        return `${maskedLocalPart}@${domain}`;
    } else if (localPart.length < 5 && localPart.length > 1) {
        const maskedLocalPart = localPart.slice(0, localPart.length - 1) + '*'.repeat(1);
        return `${maskedLocalPart}@${domain}`;
    } else return email;
};

export { sleep, ensureAsync, maskEmail };
