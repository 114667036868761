import styled from 'styled-components';
import PropTypes from 'prop-types';
import { usePearlModal } from '../../../contexts/PearlModalContext';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { GhostButton, ImageGallery } from 'dbi-pearl-ui-kit';
import { ModalImageGallery } from '../ModalImageGallery/ModalImageGallery';
import { useWindowMonitor, SCREENSIZEMODES } from '../../../lib/ScreenSizeService';
import { SectionHeader } from '../SectionHeader/SectionHeader';

const WebstoreGalleryStyled = styled.div`
    display: flex;
    flex-direction: column;

    .gallery-toggle-button {
        align-self: center;
    }

    @media (max-width: 1050px) {
        .gallery-toggle-button {
            width: 60%;
        }
    }
`;

// will never display flagged overlays or placeholder for invalid images
export default function WebstoreGallery(props) {
    const { webstore, sectionRef, sectionId, iconName, banner } = props;
    const { t } = useTranslation();

    const { openModal } = usePearlModal();
    const [cols, setCols] = useState(3);

    useWindowMonitor((mode) => {
        setCols(mode === SCREENSIZEMODES.Desktop ? 3 : 2);
    });

    const [showAllImages, setShowAllImages] = useState(!(webstore.Media?.images?.length > 6));

    if (!webstore.Media?.images?.length) {
        return (
            <WebstoreGalleryStyled ref={sectionRef} id={sectionId} data-testid="media-gallery">
                <SectionHeader
                    title={t('WebstoreSections.Gallery.Title')}
                    iconName={iconName}
                    banner={banner}
                    description={t('WebstoreSections.Gallery.Description')}
                />
                <div className="empty-gallery-container" data-testid="empty-gallery-container">
                    <p>{t('WebstoreSections.Media.NoMediaUploaded')}</p>
                </div>
            </WebstoreGalleryStyled>
        );
    }

    // grab these early to prevent re-rendering / re-slicing on ShowMore toggle
    /* Flagged photos will never appear in the gallery */
    const images = webstore.Media.images?.filter((x) => x.validationResult || x.adminApproved);
    const sliced = images.slice(0, 6);

    const showMoreOrLessButton = (
        <GhostButton
            id="media-see-more-button"
            data-testid="gallery-toggle-button"
            variant="accent"
            onClick={() => setShowAllImages(!showAllImages)}
            className="gallery-toggle-button"
        >
            {showAllImages
                ? t('WebstoreSections.Media.SeeLessButtonLabel')
                : t('WebstoreSections.Media.SeeAllMediaButtonLabel')}
        </GhostButton>
    );

    return (
        <WebstoreGalleryStyled ref={sectionRef} id={sectionId}>
            <SectionHeader
                title={t('WebstoreSections.Gallery.Title')}
                iconName={iconName}
                banner={banner}
                description={t('WebstoreSections.Gallery.Description')}
            />
            <div>
                <ImageGallery
                    cols={cols}
                    imageVariant="tile"
                    images={showAllImages ? images : sliced}
                    onImageClick={(index) =>
                        openModal(<ModalImageGallery images={images} initIndex={index} />)
                    }
                ></ImageGallery>
            </div>
            {images.length > 6 && showMoreOrLessButton}
        </WebstoreGalleryStyled>
    );
}

WebstoreGallery.propTypes = {
    /**
     * Webstore object - placeholder
     */
    webstore: PropTypes.object,
    /**
     * Ref object - used for navigation bar indicator
     */
    sectionRef: PropTypes.object,
};
