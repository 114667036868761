import SearchOptionList from '../../../event-user/components/Search/SearchOptionList/SearchOptionList';
import { GeocodeService } from '../../../lib/apis/GeocodeService';
import { useOnChangeDebounce, loadAutocompleteService } from 'framework';
import { IconName, TextInput } from 'dbi-pearl-ui-kit';
import React, { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CityStateAutoCompleteStyled from './CityStateAutoComplete.styled';
import { useTranslation } from 'react-i18next';

const searchResultToLocationParser = (searchResult, location) => {
    for (const field of searchResult?.addressComponents) {
        const fieldType = field.types[0];

        switch (fieldType) {
            case 'street_number': {
                location.addressLine1 = `${field.long_name} `;
                break;
            }
            case 'route': {
                location.addressLine1 += field.short_name;
                break;
            }
            case 'locality': {
                location.locationCity = field.long_name;
                break;
            }
            case 'administrative_area_level_1': {
                location.locationState = field.short_name;
                break;
            }
            case 'postal_code': {
                location.locationZipCode = `${field.long_name || ''}`;
                break;
            }
            case 'postal_code_suffix': {
                location.locationZipCode = `${location.locationZipCode}-${field.long_name || ''}`;
                break;
            }
            case 'placeId': {
                location.googlePlaceId = field.long_name;
                break;
            }
            default: {
                break;
            }
        }
    }

    location.displayText = searchResult.displayText;
    location.latitude = searchResult.latitude;
    location.longitude = searchResult.longitude;
    location.googlePlaceId = searchResult.placeId;

    return location;
};

const CityStateAutoComplete = (props) => {
    const {
        disableInput,
        setSearchResult,
        hideOptions,
        fieldName,
        startIcon = IconName.MAP_MARKER_OUTLINE,
        providedLabel,
    } = props;

    const [searchOptions, setSearchOptions] = useState(null);
    const { debounceChangeHandler } = useOnChangeDebounce(150);
    const inputRef = useRef(null);

    const { setValue } = useFormContext();
    const { t } = useTranslation();

    const geocodeApi = GeocodeService();

    const displaySuggestions = function (predictions, status) {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
            setSearchOptions([{ options: [] }]);
            return;
        }
        setSearchOptions(
            predictions.map((prediction) => ({
                displayText: prediction.description.split(', USA').slice(0, -1)[0],
                placeId: prediction.place_id,
            }))
        );
    };

    const setLocationSearchValue = async (selectedLocation) => {
        if (selectedLocation.latitude && selectedLocation.longitude) {
            const coordinates = await geocodeApi.getReverseGeoCode(
                selectedLocation.latitude,
                selectedLocation.longitude
            );
            setSearchResult({ ...selectedLocation, ...coordinates });
            setValue(fieldName, selectedLocation.displayText);
        } else if (selectedLocation.placeId) {
            const coordinates = await geocodeApi.getGeocodeByPlaceId(selectedLocation.placeId);
            setSearchResult({ ...selectedLocation, ...coordinates });
            setValue(fieldName, selectedLocation.displayText);
        }
        setSearchOptions(null);
    };

    const clearForm = () => {
        setSearchResult(null);
    };

    const onInputChange = (event) => {
        debounceChangeHandler(event, inputRef, (searchValue) =>
            loadAutocompleteService(searchValue, displaySuggestions, {
                scriptId: `${fieldName}-autocomplete-script`,
                searchValue,
            })
        );
    };

    const handleSearchChange = (event) => {
        setValue(fieldName, event.target.value);
        onInputChange && onInputChange(event);
    };

    return (
        <CityStateAutoCompleteStyled>
            <TextInput
                formFieldName={fieldName}
                startIcon={startIcon}
                onChange={handleSearchChange}
                onFocus={clearForm}
                disabled={disableInput}
                autoComplete="off"
                providedLabel={providedLabel || t('Forms.ServiceArea.ServiceAreaLabel')}
            />
            {searchOptions?.length > 0 && !hideOptions && (
                <div className="optionsList">
                    <SearchOptionList
                        options={searchOptions}
                        optionType={'button'}
                        updateSearch={setLocationSearchValue}
                    />
                </div>
            )}
        </CityStateAutoCompleteStyled>
    );
};

export { CityStateAutoComplete, searchResultToLocationParser };
