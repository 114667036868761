import { WebstoreAwards } from 'framework';
import { useTranslation } from 'react-i18next';
import { NoInformationProvided } from '../NoInformationProvided/NoInformationProvided';
import { UpgradeSubscription } from '../UpgradeSubscription/UpgradeSubscription';
import { IconName } from 'dbi-pearl-ui-kit';
import { useStore } from 'stores/Store';

const AwardsSection = (props) => {
    const { webstore, sectionRef, sectionId, showFlaggedOverlay } = props;
    const { t } = useTranslation();
    const { vendor } = useStore();
    const needsUpgrade =
        // !webstore.ActiveFeatures?.feature_awards;
        !vendor?.stripeData?.subscription?.activeFeatures?.includes('feature_awards') ||
        webstore.SelectedPlan === 'planone';

    return (
        <>
            {webstore.Awards?.length ? (
                <WebstoreAwards
                    webstore={webstore}
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    showFlaggedOverlay={showFlaggedOverlay}
                    iconName={needsUpgrade ? IconName.LOCK : null}
                    banner={!!needsUpgrade && <UpgradeSubscription />}
                />
            ) : (
                <NoInformationProvided
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    title={t('WebstoreSections.Awards.Title')}
                    description={t('WebstoreSections.Awards.Description')}
                    iconName={needsUpgrade ? IconName.LOCK : null}
                    banner={!!needsUpgrade && <UpgradeSubscription />}
                />
            )}
        </>
    );
};

export { AwardsSection };
