import { WebstorePreferredPartners } from 'framework';
import { useTranslation } from 'react-i18next';
import { NoInformationProvided } from '../NoInformationProvided/NoInformationProvided';

function PreferredPartnersSection(props) {
    const { webstore, sectionRef, sectionId } = props;
    const { t } = useTranslation();

    const handleVendorClick = (url) => {
        window.open(`${process.env.REACT_APP_BASE_URL}/${url}`, '_blank');
    };

    return (
        <>
            {webstore.Partners?.length ? (
                <WebstorePreferredPartners
                    webstore={webstore}
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    onViewVendorClick={handleVendorClick}
                />
            ) : (
                <NoInformationProvided
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    title={t('WebstoreSections.PreferredPartners.Title')}
                    description={t('WebstoreSections.PreferredPartners.Description')}
                />
            )}
        </>
    );
}

export { PreferredPartnersSection };
