import React from 'react';
import PriceTierTileStyled from './PriceTierTileNew.styled';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { IconName, IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';

function PriceTierTileNew(props) {
    const {
        titleText,
        bodyText,
        monetarySymbol,
        tierId,
        tierValue,
        formFieldName,
        onClick,
        isChecked,
        disabled,
    } = props;
    const { register } = useFormContext();

    return (
        <PriceTierTileStyled isChecked={isChecked} className="boxInputWrapper">
            <input
                type="radio"
                id={tierId}
                data-testid={tierId}
                name="price-tier"
                value={tierValue}
                defaultChecked={isChecked}
                disabled={disabled}
                onClick={onClick ? (e) => onClick(e) : null}
                {...register(formFieldName)}
            />
            <label htmlFor={tierId}>
                <div data-testid={`box-${tierId}`} className={`box ${disabled ? 'disabled' : ''}`}>
                    <div className="boxLeftContent">
                        {monetarySymbol === '$' ? (
                            <PearlIcon
                                iconName={IconName.BUDGET_FRIENDLY}
                                size={IconSizes.XXL}
                                data-testid="budget-friendly"
                            />
                        ) : (
                            ''
                        )}
                        {monetarySymbol === '$$' ? (
                            <PearlIcon
                                iconName={IconName.STANDARD}
                                size={IconSizes.XXL}
                                data-testid="standard-budget"
                            />
                        ) : (
                            ''
                        )}
                        {monetarySymbol === '$$$' ? (
                            <PearlIcon
                                iconName={IconName.PREMIUM}
                                size={IconSizes.XXL}
                                data-testid="premium-budget"
                            />
                        ) : (
                            ''
                        )}
                        {monetarySymbol === '$$$$' ? (
                            <PearlIcon
                                iconName={IconName.LUXURY}
                                size={IconSizes.XXL}
                                data-testid="luxury-budget"
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="boxMiddleContent">
                        <h5 className="h5 boxTitle">{titleText}</h5>
                        <p className="p2 boxBody">{bodyText}</p>
                    </div>
                    <div className="boxRightContent" />
                </div>
            </label>
        </PriceTierTileStyled>
    );
}

PriceTierTileNew.propTypes = {
    /**
     * Title to display in tile
     */
    titleText: PropTypes.string,

    /**
     * Body text to display in tile
     */
    bodyText: PropTypes.string,

    /**
     * Symbols displayed within the tile's circle graphic
     */
    monetarySymbol: PropTypes.string,

    /**
     * Used for test IDs and accessibility
     */
    tierId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * Value of the tile to register with form when clicked
     */
    tierValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * Name of field to register with form
     */
    formFieldName: PropTypes.string,

    /**
     * Controls whether the checkmark is displayed
     */
    isChecked: PropTypes.bool,

    /**
     * If provided, function that is called when tile is clicked
     */
    onClick: PropTypes.func,

    /**
     * Controls whether the tile is interactable
     */
    disabled: PropTypes.bool,
};

export { PriceTierTileNew };
