import styled from 'styled-components';

const TravelOptionsStyled = styled.div`
    .sliderMapContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin-top: 20px;
        @media(max-width: 815px) {
            flex-direction: column;
        }
    }

    .sliderMapContainer > div {
        width: 100%;
        max-width: 534px;
    }


    @media (max-width: 1440px) {
        .sliderMapContainer > div {
            width: 100%;
        }
    }

    .mapStyles {
        width: 100%;
        max-width: 534px;
        height: 229px;
    }
    .sliderStyles {
        width: 100%;
        max-width: 280px;
    }
    .mapStyles {
        width: 100%;
        max-width: 534px;
        height: 229px;
    }

    @media (max-width: 1024px) {
        .mapStyles {
            max-width: 100%;
        }

        .sliderStyles {
            max-width: 100%;
        }
    }
    .sliderStyles {
        width: 100%;
        max-width: 280px;
        @media (max-width: 815px) {
            max-width: 100%;
        }
        .MuiSlider-thumbSizeMedium {
            background-color: #e41395;
            width: 16px;
            height: 44px;
            border-radius: 10px;
        }
        .MuiSlider-rail {
            width: 280px;
            height: 16px;
            background-color: #fbdcef;
            @media (max-width: 815px) {
            width:100%;
        }
        }
        .MuiSlider-track {
            border: none;
            background: none;
        }
        .MuiSlider-markActive {
            background-color: none;
        }
        .MuiSlider-mark {
            background: none;
        }
    }
    .sliderWithDots {
        display: flex;
    }
    .sliderDot {
        width: 4px;
        height: 4px;
        background-color: #e41395;
        border-radius: 50%;
    }
    .rightside{
        position: relative;
        right: 12px;
        bottom: -13px;
    }    
    .leftside{
        position: relative;
        left: 10px;
        bottom: -13px;
    }    
    .milesText {
        color: #132122;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 1.65px;
        text-transform: uppercase;
    }
    .milesContainer {
        align-self: flex-start;
        @media(max-width: 815px) {
            align-self: center;
            padding-bottom: 24px;
        }
    }
    }
    .milesWithSlider {
        display: block;
    }
    .travelHeading {
        margin-top: 0px;
        font-size: 14px;
        line-height: 16.8px;
        font-weight: bold;
    }
    .cityStateStyles {
        margin-top: 20px;
    }        
    label {
        border-radius: 8px;
        border: 2px solid ${(props) => props.theme.colors.foreground.border};
        box-shadow: 0px 2px 6px 0px rgba(19, 33, 34, 0.08);

        &:has(input:checked) {
            border: 2px solid ${(props) => props.theme.colors.primary.base} !important;
            .icon-checkmark:before {
                display: none;
            }
            &:hover {
                background-color: ${(props) => props.theme.colors.background.base};
                color: black;
            }
        }
        & > div .p3 {
            text-transform: capitalize;
        }
    }

    .ServiceArea-container {
        border-radius: 8px;
    }
`;

export { TravelOptionsStyled };
