const socialLinksPrefixes = {
    website: {
        url: 'https://',
        displayText: 'https://',
    },
    youtube: {
        url: 'https://youtube.com/',
        displayText: 'youtube.com/',
    },
    facebook: {
        url: 'https://facebook.com/',
        displayText: 'facebook.com/',
    },
    vimeo: {
        url: 'https://vimeo.com/',
        displayText: 'vimeo.com/',
    },
    instagram: {
        url: 'https://www.instagram.com/',
        displayText: '@',
    },
    tikTok: {
        url: 'https://www.tiktok.com/@',
        displayText: '@',
    },
    pinterest: {
        url: 'https://www.pinterest.com/',
        displayText: 'pinterest.com/',
    },
    spotify: {
        url: 'https://open.spotify.com/artist/',
        displayText: 'open.spotify.com/artist/',
    },
    twitter: {
        url: 'https://twitter.com/',
        displayText: '@',
    },
    soundCloud: {
        url: 'https://soundcloud.com/',
        displayText: 'soundcloud.com/',
    },
    google: {
        url: 'https://',
        displayText: 'https://',
    },
    yelp: {
        url: 'https://yelp.com/biz/',
        displayText: 'yelp.com/biz/',
    },
    x: {
        url: 'https://x.com/',
        displayText: '@',
    },
    loveStoriesTV: {
        url: 'https://lovestoriestv.com/',
        displayText: 'lovestoriestv.com/',
    },
};

export { socialLinksPrefixes };
