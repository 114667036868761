import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const BasicsEditFormStyled = styled.section`
    .basics-category {
        position: relative;
        .mini-container {
            margin-top: 0;
        }
    }

    .visit-pearl-page {
        width: 100%;
    }
    .tooltipStyle {
        width: fit-content;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        right: -32px;
        top: 16px;
        @media (max-width: 815px) {
            z-index: 1000;
            right: 40px;
        }
    }
    .formRowStyle {
        @media (max-width: 815px) {
            margin-top: 10px !important;
        }
    }
    .WebstoreName-container,
    .WebstoreUrl-container,
    .visit-pearl-page,
    .menu-container,
    .VendorCategoryId-select {
        border-radius: 8px !important;
    }
`;

const VisitPearlPageButton = styled.button`
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1.65px;
    min-width: 131px;
    background: none;
    border: none;
    padding: 0px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    position: relative;
    color: ${(props) => (props.disabled ? '#717777' : '#132122')} !important;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

    &::after {
        content: '';
        display: block;
        margin: 4px auto 0; /* Adjust spacing below the text */
        width: 100%; /* Adjust underline length */
        height: 1px; /* Underline thickness */
        background-color: ${(props) => (props.disabled ? '#717777' : '#132122')};
    }

    &:focus {
        outline: none; /* Remove focus outline */
    }

    &:focus::after {
        height: ${(props) => (props.disabled ? '1px' : '2px')};
    }

    &:hover {
        color: ${(props) => (props.disabled ? '#717777' : '#132122')};
    }

    &:hover::after {
        height: ${(props) => (props.disabled ? '1px' : '2px')};
    }

    ${({ theme }) => theme.pearlBreaks.down(BreakpointSizes.MD)} {
        position: absolute;
        right: 0;
    }
`;

const VisitPearlPageButtonContainer = styled.div`
    width: 131px;
    position: relative;
    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        width: 100%;
        margin-bottom: 19px;
    }
`;

export { BasicsEditFormStyled, VisitPearlPageButton, VisitPearlPageButtonContainer };
