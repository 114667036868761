let placesService;
const url = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;

// https://developers.google.com/maps/documentation/javascript/places

function loadScript(callback, elem, options) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                handleScriptLoad(callback, elem, options);
            }
        };
    } else {
        script.onload = () => handleScriptLoad(callback, elem, options);
    }
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
}

function handleScriptLoad(callback, elem, options) {
    placesService = new window.google.maps.places.PlacesService(elem);
    if (placesService) {
        placesService.getDetails(options, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                callback(place, status);
            } else {
                callback(null, status);
            }
        });
    }
}

export { loadScript };
