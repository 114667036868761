import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const ServiceTypeOptionsStyled = styled.div`
    .store-options {
        gap: 4px !important;
        margin-top: 16px;
    }
    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .store-options {
            flex-wrap: wrap;
        }

        .option-container {
            input {
                width: unset;
            }
        }
    }
    .physicalStoreToggle {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > p {
            margin: 0px;
            line-height: 24px !important;
        }
        .MuiFormControlLabel-root {
            margin-right: 0px;
        }
    }
`;

export { ServiceTypeOptionsStyled };
