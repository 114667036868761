import styled from 'styled-components';

const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    max-width: 500px;
    height: auto;
    position: relative;
    top: 100px;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid var(--color-gray200);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.06) 0px 0px 2px,
        rgba(0, 0, 0, 0.04) 0px 0px 1px;
    border-radius: 5px;
    .emailStyle {
        width: 100%;
    }
    @media (max-width: 768px) {
        width: 100%;
        position: relative;
        top: 0px;
        height: 100vh;
        display: block;
    }
    .btnStyle {
        margin: 24px 0px;
        width: 100%;
    }
    .inputStyle {
        margin-top: 16px;
        width: 100%;
    }
    .signupBtnStyle {
        width: 100%;
    }
    .isResendDisabled-true {
        color: #717777;
        margin-bottom: 10px;
    }
    .isResendDisabled-false {
        color: #132122;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .resendButton-true {
        display: flex;
        border-bottom: 2px solid #717777;
        width: fit-content;
        margin-top: 16px;
    }
    .resendButton-false {
        display: flex;
        border-bottom: 2px solid #132122;
        width: fit-content;
        margin-top: 16px;
    }
    .infoStyle {
        padding-top: 3.5px;
        padding-left: 5px;
    }
    .emailError {
        color: #de1717;
        margin-top: 10px;
    }
    .eyeIconPosition {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }
    .nameStyle {
        display: flex;
        width: 100%;
        margin-top: 16px;
        gap: 16px;
    }
    .firstNameStyle {
        width: 100%;
    }
    .lastNameStyle {
        width: 100%;
    }
    .phoneNumberStyle {
        width: 100%;
        margin-top: 26px;
    }
`;

const Title = styled.h2`
  text-transform: capitalize; 
  font-style: normal
  line-height: 18px;
  letter-spacing: 1.5px;      
  font-size: 12px;
  margin: 0;
  color: #132122;
  width: 100%;
`;

const SubTitle = styled.h1`
    text-transform: capitalize;
    font-size: 32px;
    margin: 0;
    width: 100%;
`;

const Subtitle = styled.p`
    margin-top: 12px;
    font-size: 14px;
    color: #132122;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    width: 100%;
    margin-bottom: 24px;
`;

const Divider = styled.hr`
    width: 100%;
    margin-bottom: 24px;
    margin-top: 10px;
`;

const ResendLink = styled.a`
    font-family: 'Objektiv Mk2 Bold';
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 1.65px;
    text-transform: uppercase;
    margin-top: 5px;
    text-decoration: none;
`;

const ForgotPasswordStyle = styled.div`
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
    .p1,
    p {
        margin-top: 0px;
    }
`;
export { LoginContainer, Title, SubTitle, Subtitle, Divider, ResendLink, ForgotPasswordStyle };
