import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { VendorSocialFormStyled } from './VendorSocialForm.styled';
import PearlForm from 'vendor/components/forms/PearlForm';
import { SocialMedia, EmptyCaptions } from 'assets/Defaults';
import { AccountSetupStepLayout, AccountSetupStepGraphic, AccountSetupStepFooter } from 'framework';
import { SocialLinksSection } from '../../Webstore/SocialLinksSection/SocialLinksSection';
import { useWebstoreSetupWizard } from '../WebstoreSetupContext';
import { useWebstoreContext } from '../../../contexts/WebstoreContext/WebstoreContext';
import {
    useTealium,
    buildWebstoreSetupStepPageData,
    buildWebstoreSetupStepNextClickData,
} from '../../../../Tealium';
import { WebstoreSetupHeader } from '../WebstoreSetupHeader';

const formName = 'SocialLinks';

function SocialFooter() {
    const { onBack, progress, onSubmit, onSkip } = useWebstoreSetupWizard();

    const {
        watch,
        formState: { isDirty },
    } = useFormContext();

    const allValues = watch();

    const isSkippable = !Object.values(allValues).some((x) => x);
    const shouldPatch = isSkippable && isDirty;
    const functionToPatch = () => onSubmit(allValues);
    const functionToSkip = () => onSkip();

    return (
        <AccountSetupStepFooter
            handleOnBack={() => onBack()}
            handleOnSkip={isSkippable ? (shouldPatch ? functionToPatch : functionToSkip) : null}
            progress={progress}
        />
    );
}

const VendorSocialForm = () => {
    const { link, view } = useTealium();
    const { webstore } = useWebstoreContext();
    const { onSubmit, onReturnToBusinessLocations, currentStepCount, totalSteps } =
        useWebstoreSetupWizard();
    const { t } = useTranslation();

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const videoDetails = {
        image: SocialMedia,
        altText: `Forms.${formName}.GifAltText`,
        captions: EmptyCaptions,
    };

    const formOptions = {
        defaultValues: {
            Website: webstore.Website,
            YouTube: webstore.YouTube,
            Google: webstore.Google,
            Pinterest: webstore.Pinterest,
            Yelp: webstore.Yelp,
            Twitter: webstore.Twitter,
            Facebook: webstore.Facebook,
            TikTok: webstore.TikTok,
            Instagram: webstore.Instagram,
            SoundCloud: webstore.SoundCloud,
            Spotify: webstore.Spotify,
            Vimeo: webstore.Vimeo,
            LoveStoriesTV: webstore.LoveStoriesTV,
        },
    };

    const handleOnSubmit = (data) => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepNextClickData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps,
            data,
            data
        );

        link(tealiumData);

        onSubmit(data);
    };

    return (
        <PearlForm
            StyledForm={VendorSocialFormStyled}
            formName={formName}
            formOptions={formOptions}
            onSubmit={handleOnSubmit}
        >
            <AccountSetupStepLayout
                header={
                    <WebstoreSetupHeader
                        onReturnToBusinessLocations={onReturnToBusinessLocations}
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps}
                    />
                }
                formComponent={<SocialLinksSection />}
                image={<AccountSetupStepGraphic {...videoDetails} />}
                footer={<SocialFooter />}
            />
        </PearlForm>
    );
};

export { VendorSocialForm };
