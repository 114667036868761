/* eslint-disable complexity */
import { IconSizes, LinkButton, PearlIcon, SolidButton, TextInput } from 'dbi-pearl-ui-kit';
import { PearlError, PhoneNumberInput, usePearlToast } from 'framework';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import PasswordValidation from '../VendorBasicDetails/PasswordValidation';
import { useEffect, useState } from 'react';
import TooltipComponent from '../Utility/TooltipComponent/TooltipComponent';
import { ReactComponent as HideView } from '../../../assets/logos/hideView.svg';
import { VendorAuthService } from 'lib/apis/VendorAuthService';
import {
    LoginContainer,
    Title,
    SubTitle,
    Subtitle,
    Divider,
    ResendLink,
    ForgotPasswordStyle,
} from './VendorLoginForm.styled';

const VendorLoginForm = ({
    showPasswordInput,
    showOTPInput,
    showResetPasswordInputs,
    showSignUpButton,
    LoginClickHandler,
    handleOTPSubmit,
    handleSignUp,
    handlePasswordResetSubmit,
    handleLogin,
    showFirstandLastNames,
    showPhoneNumber,
    userFieldsReq,
}) => {
    const { t } = useTranslation();
    const { toastSuccess } = usePearlToast();
    const { getValues, setError, watch, setValue, trigger, control, clearErrors } =
        useFormContext();
    const [timer, setTimer] = useState(60);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState('weak');
    const [emailOTPError, setEmailOTPErrror] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [passwordResetFields, setPasswordResetFields] = useState(false);
    const vendorAuthService = VendorAuthService();
    const [validationStatus, setValidationStatus] = useState({
        length: false,
        uppercaseLowercase: false,
        numberOrSymbol: false,
        noEmailOrName: false,
    });
    const {
        Emailaddress,
        EnterOTP,
        Enterpassword,
        Confirmpassword,
        Firstname,
        Lastname,
        Phonenumber,
    } = getValues();
    const passwordValue = watch('Enterpassword');
    const confirmPasswordValue = watch('Confirmpassword');
    const emailValue = watch('Emailaddress');
    const clickHandler = () => {
        if (!Emailaddress) {
            setError('Emailaddress', {
                type: 'manual',
                message: 'Email address is required',
            });
        } else {
            LoginClickHandler(getValues(), setError);
        }
    };
    const OTPHandler = () => {
        if (!EnterOTP) {
            setError('EnterOTP', {
                type: 'manual',
                message: 'Please enter the OTP',
            });
        } else {
            if (EnterOTP.length === 6) {
                handleOTPSubmit(getValues(), setError);
            }
        }
    };
    const passwordHandler = () => {
        if (passwordValue !== confirmPasswordValue) {
            return;
        }
        if (!Enterpassword) {
            setError('Enterpassword', {
                type: 'manual',
                message: 'Please enter the password',
            });
        }
        if (!Confirmpassword) {
            setError('Confirmpassword', {
                type: 'manual',
                message: 'Please confirm the password',
            });
        }
        if (!Firstname && userFieldsReq.includes('firstName')) {
            setError('Firstname', {
                type: 'manual',
                message: 'Please enter the first name',
            });
        }
        if (!Lastname && userFieldsReq.includes('lastName')) {
            setError('Lastname', {
                type: 'manual',
                message: 'Please enter the last name',
            });
        }
        if (!Phonenumber && userFieldsReq.includes('phoneNumber')) {
            setError('Phonenumber', {
                type: 'manual',
                message: 'Please enter the phone number',
            });
        }
        if (passwordStrength === 'strong') {
            handlePasswordResetSubmit(getValues(), setError);
        }
    };

    useEffect(() => {
        if (confirmPasswordValue) {
            trigger('Confirmpassword');
        }
    }, [passwordValue, confirmPasswordValue, trigger]);

    useEffect(() => {
        if (showOTPInput && isResendDisabled && timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(countdown);
                        setIsResendDisabled(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(countdown);
        }
    }, [isResendDisabled, showOTPInput, timer]);
    useEffect(() => {
        if (passwordResetFields && isResendDisabled && timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(countdown);
                        setIsResendDisabled(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(countdown);
        }
    }, [isResendDisabled, passwordResetFields, timer]);

    useEffect(() => {
        if (emailValue) {
            const lowercaseEmail = emailValue.toLowerCase();
            if (emailValue !== lowercaseEmail) {
                setValue('Emailaddress', lowercaseEmail);
            }
        }
    }, [emailValue, setValue]);
    useEffect(() => {
        // eslint-disable-next-line complexity
        const validatePassword = () => {
            const email = watch('Emailaddress') || '';
            const length = passwordValue?.length >= 8;
            const uppercaseLowercase = /[A-Z]/.test(passwordValue) && /[a-z]/.test(passwordValue);
            const numberOrSymbol =
                /\d/.test(passwordValue) && /[!@#$%^&*(),.?":{}|<>]/.test(passwordValue);
            const noEmailOrName =
                !passwordValue ||
                !email ||
                (passwordValue &&
                    (!email ||
                        !passwordValue.toLowerCase().includes(email.split('@')[0]?.toLowerCase())));
            setValidationStatus({
                length,
                uppercaseLowercase,
                numberOrSymbol,
                noEmailOrName,
            });

            if (length && uppercaseLowercase && numberOrSymbol && noEmailOrName) {
                setPasswordStrength('strong');
            } else if (length && (uppercaseLowercase || numberOrSymbol)) {
                setPasswordStrength('medium');
            } else {
                setPasswordStrength('weak');
            }
        };

        validatePassword();
    }, [passwordValue, watch('Emailaddress')]);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };

    const handleResendEmailOTP = async () => {
        if (!isResendDisabled) {
            setIsResendDisabled(true);
            setTimer(60);
            setEmailOTPErrror('');

            try {
                await vendorAuthService.resendEmailOTP(Emailaddress);
            } catch (error) {
                setEmailOTPErrror('Failed to resend Email OTP');
                console.error('>> Failed to resend Email OTP:', error);
            }
        }
    };
    const handleResendOTP = async () => {
        if (!isResendDisabled) {
            setIsResendDisabled(true);
            setTimer(60);
            clearErrors();
            try {
                await vendorAuthService.forgotPassword(Emailaddress);
            } catch (error) {
                console.error('>> Failed to resend Email OTP:', error);
            }
        }
    };

    const handleForgotPassword = () => {
        setForgotPassword(true);
        setPasswordResetFields(false);
        setValue('EnterOTP', '');
        setValue('Enterpassword', '');
        setValue('Confirmpassword', '');
        setShowPassword(false);
        setShowConfirmPassword(false);
    };
    const handleBackToSignin = () => {
        setForgotPassword(false);
        setShowPassword(false);
        clearErrors();
    };
    const handleResetPassword = async () => {
        setIsResendDisabled(true);
        setTimer(60);
        if (emailValue.length > 0) {
            try {
                await vendorAuthService.forgotPassword(Emailaddress);
            } catch (error) {
                console.error('>> Failed to send OTP:', error);
            }
            setPasswordResetFields(true);
        }
    };
    const handleUpdatePassword = async () => {
        const requiredFields = [
            { field: EnterOTP, name: 'EnterOTP', message: 'Please enter the OTP' },
            { field: Enterpassword, name: 'Enterpassword', message: 'Please enter the password' },
            {
                field: Confirmpassword,
                name: 'Confirmpassword',
                message: 'Please confirm the password',
            },
        ];

        for (const { field, name, message } of requiredFields) {
            if (!field) {
                setError(name, {
                    type: 'manual',
                    message,
                });
            }
        }
        if (passwordValue !== confirmPasswordValue) {
            return;
        }
        if (passwordStrength === 'strong') {
            try {
                await vendorAuthService.verifyCodeForgotPassword(
                    Emailaddress,
                    EnterOTP,
                    Enterpassword
                );
                toastSuccess('Password reset successfully');
                setForgotPassword(false);
                setShowPassword(false);
                clearErrors();
            } catch (e) {
                setError('EnterOTP', {
                    type: 'manual',
                    message: 'OTP is incorrect or expired',
                });
            }
        }
    };

    return (
        <LoginContainer data-testid="LoginPage-container" data-password-strength={passwordStrength}>
            {!forgotPassword && (
                <>
                    <Title>{t('Forms.BasicDetails.VendorLoginText.Vendor')}</Title>
                    <SubTitle>{t('Forms.BasicDetails.VendorLoginText.WelcomeText')}</SubTitle>
                    <Subtitle>{t('Forms.BasicDetails.VendorLoginText.login')}</Subtitle>
                    <Divider />
                </>
            )}
            {!forgotPassword && (
                <div className="emailStyle">
                    <TextInput formFieldName="Emailaddress" type="email" />
                    <PearlError name="Emailaddress" />
                </div>
            )}
            {showPasswordInput && !forgotPassword && (
                <>
                    <div className="inputStyle" style={{ position: 'relative' }}>
                        <TextInput
                            formFieldName="Password"
                            type={showPassword ? 'text' : 'password'}
                        />
                        <PearlError name="Password" />
                        {showPassword && (
                            <PearlIcon
                                iconName="view"
                                size={IconSizes.MD}
                                onClick={togglePasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                        {!showPassword && (
                            <HideView
                                onClick={togglePasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                    </div>
                    <SolidButton
                        className="btnStyle"
                        type="submit"
                        onClick={() => handleLogin(getValues())}
                    >
                        {t('Forms.BasicDetails.VendorLoginText.Login')}
                    </SolidButton>
                    <ForgotPasswordStyle onClick={handleForgotPassword}>
                        <p>{t('Forms.BasicDetails.Forgotpassword.Title') + '?'}</p>
                    </ForgotPasswordStyle>
                </>
            )}
            {showOTPInput && (
                <>
                    <div className="inputStyle">
                        <TextInput formFieldName="EnterOTP" />
                        <PearlError name="EnterOTP" />
                        {emailOTPError && (
                            <div data-testid="emailError" className="emailError">
                                {emailOTPError}
                            </div>
                        )}
                        <div className={`resendButton-${isResendDisabled}`}>
                            <ResendLink
                                className={`isResendDisabled-${isResendDisabled}`}
                                onClick={handleResendEmailOTP}
                            >
                                {t('Forms.BasicDetails.VerificationModalContent.ResendOTP')}
                            </ResendLink>
                            <TooltipComponent
                                header={''}
                                showCloseButton={false}
                                width={'291'}
                                height={'95'}
                                bottom={'132'}
                                left={'570'}
                                bottomSmall={'135'}
                                leftSmall={'545'}
                                arrowBottom={'-22'}
                                arrowLeft={'13'}
                                content={t(
                                    'Forms.BasicDetails.VerificationModalContent.TooltipContent'
                                )}
                            />
                        </div>
                    </div>
                    <SolidButton
                        className="btnStyle"
                        type="submit"
                        onClick={(e) => {
                            e.preventDefault();
                            OTPHandler(getValues());
                        }}
                    >
                        {t('Forms.BasicDetails.VendorLoginText.Submit')}
                    </SolidButton>
                </>
            )}
            {showSignUpButton && (
                <SolidButton className="btnStyle" onClick={handleSignUp}>
                    {t('Forms.BasicDetails.VendorLoginText.Signup')}
                </SolidButton>
            )}
            {showResetPasswordInputs && (
                <>
                    {showFirstandLastNames && (
                        <div className="nameStyle">
                            <div className="firstNameStyle">
                                <TextInput formFieldName="Firstname" />
                                <PearlError name="Firstname" />
                            </div>
                            <div className="lastNameStyle">
                                <TextInput formFieldName="Lastname" />
                                <PearlError name="Lastname" />
                            </div>
                        </div>
                    )}
                    {showPhoneNumber && (
                        <div className="phoneNumberStyle">
                            <Controller
                                data-testid="Phonenumber"
                                name={'Phonenumber'}
                                control={control}
                                render={({ field }) => (
                                    <PhoneNumberInput
                                        field={field}
                                        className="custom-phone-input"
                                    />
                                )}
                            />
                            <PearlError name="Phonenumber" />
                        </div>
                    )}
                    <div className="inputStyle" style={{ position: 'relative' }}>
                        <TextInput
                            type={showPassword ? 'text' : 'password'}
                            formFieldName="Enterpassword"
                        />
                        <PearlError name="Enterpassword" />
                        {showPassword && (
                            <PearlIcon
                                iconName="view"
                                size={IconSizes.MD}
                                onClick={togglePasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                        {!showPassword && (
                            <HideView
                                onClick={togglePasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                        {passwordValue && (
                            <PasswordValidation
                                passwordStrength={passwordStrength}
                                validationStatus={validationStatus}
                            />
                        )}
                    </div>
                    <div className="inputStyle" style={{ position: 'relative' }}>
                        <TextInput
                            type={showConfirmPassword ? 'text' : 'password'}
                            formFieldName="Confirmpassword"
                        />
                        <PearlError name="Confirmpassword" />
                        {showConfirmPassword && (
                            <PearlIcon
                                iconName="view"
                                size={IconSizes.MD}
                                onClick={toggleConfirmPasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                        {!showConfirmPassword && (
                            <HideView
                                onClick={toggleConfirmPasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                    </div>
                    <SolidButton className="btnStyle" onClick={() => passwordHandler(getValues())}>
                        {t('Forms.BasicDetails.VendorLoginText.Submit')}
                    </SolidButton>
                </>
            )}
            {!showOTPInput && !showResetPasswordInputs && !showPasswordInput && !showSignUpButton && (
                <>
                    <SolidButton className="btnStyle" onClick={clickHandler}>
                        {t('Forms.BasicDetails.VendorLoginText.Login')}
                    </SolidButton>
                </>
            )}
            {forgotPassword && (
                <>
                    <h2 className="forgotPasswordStyle">
                        {t('Forms.BasicDetails.Forgotpassword.Title')}
                    </h2>
                    {!passwordResetFields && (
                        <>
                            <p
                                style={{ width: '100%', marginTop: '0px' }}
                                className="enterEmailStyle"
                            >
                                {t('Forms.BasicDetails.Forgotpassword.Subtitle')}
                            </p>
                            <div className="emailStyle">
                                <TextInput formFieldName="Emailaddress" type="email" />
                                <PearlError name="Emailaddress" />
                            </div>
                        </>
                    )}
                    {passwordResetFields && (
                        <>
                            <p>
                                {t('Forms.BasicDetails.VerificationModalContent.EnterEmailCode')}
                                <b>{emailValue}</b>
                            </p>
                            <div className="inputStyle">
                                <TextInput formFieldName="EnterOTP" />
                                <PearlError name="EnterOTP" />
                                <div className={`resendButton-${isResendDisabled}`}>
                                    <ResendLink
                                        className={`isResendDisabled-${isResendDisabled}`}
                                        onClick={handleResendOTP}
                                    >
                                        {t('Forms.BasicDetails.VerificationModalContent.ResendOTP')}
                                    </ResendLink>
                                    <TooltipComponent
                                        header={''}
                                        showCloseButton={false}
                                        width={'291'}
                                        height={'95'}
                                        bottom={'132'}
                                        left={'570'}
                                        bottomSmall={'135'}
                                        leftSmall={'545'}
                                        arrowBottom={'-22'}
                                        arrowLeft={'13'}
                                        content={t(
                                            'Forms.BasicDetails.VerificationModalContent.TooltipContent'
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="inputStyle" style={{ position: 'relative' }}>
                                <TextInput
                                    type={showPassword ? 'text' : 'password'}
                                    formFieldName="Enterpassword"
                                />
                                <PearlError name="Enterpassword" />
                                {showPassword && (
                                    <PearlIcon
                                        iconName="view"
                                        size={IconSizes.MD}
                                        onClick={togglePasswordVisibility}
                                        className="eyeIconPosition"
                                    />
                                )}
                                {!showPassword && (
                                    <HideView
                                        onClick={togglePasswordVisibility}
                                        className="eyeIconPosition"
                                    />
                                )}
                                {passwordValue && (
                                    <PasswordValidation
                                        passwordStrength={passwordStrength}
                                        validationStatus={validationStatus}
                                    />
                                )}
                            </div>
                            <div className="inputStyle" style={{ position: 'relative' }}>
                                <TextInput
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    formFieldName="Confirmpassword"
                                />
                                <PearlError name="Confirmpassword" />
                                {showConfirmPassword && (
                                    <PearlIcon
                                        iconName="view"
                                        size={IconSizes.MD}
                                        onClick={toggleConfirmPasswordVisibility}
                                        className="eyeIconPosition"
                                    />
                                )}
                                {!showConfirmPassword && (
                                    <HideView
                                        onClick={toggleConfirmPasswordVisibility}
                                        className="eyeIconPosition"
                                    />
                                )}
                            </div>
                            <SolidButton className="btnStyle" onClick={handleUpdatePassword}>
                                {'Update Password'}
                            </SolidButton>
                            <LinkButton onClick={handleBackToSignin}>
                                {'Back to Sign-in'}
                            </LinkButton>
                        </>
                    )}
                    {!passwordResetFields && (
                        <>
                            <SolidButton className="btnStyle" onClick={handleResetPassword}>
                                {'Reset Password'}
                            </SolidButton>
                            <LinkButton onClick={handleBackToSignin}>
                                {'Back to Sign-in'}
                            </LinkButton>
                        </>
                    )}
                </>
            )}
        </LoginContainer>
    );
};

export default VendorLoginForm;
