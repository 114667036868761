import React, { useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MenuItem, Menu } from '@mui/material';
import {
    BorderLabelContainer,
    ToggleSwitch,
    PearlIcon,
    IconName,
    IconSizes,
} from 'dbi-pearl-ui-kit';
import styled from 'styled-components';

const ButtonStyled = styled.button`
    width: 100%;
    text-align: left;
    overflow: hidden;
    border-radius: 0;
    border: none;
    color: ${(props) => props?.theme?.colors?.foreground?.text};
    align-items: center;
    padding: 7px 15px 7px 7px;
    cursor: pointer;

    text-wrap: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;

    background-color: ${(props) => props?.theme?.colors?.background?.container};

    .icon {
        position: absolute;
        right: 10px;
        padding-top: 2px;
    }
`;

const MultiSelectStyled = styled.div`
    width: 100%;

    &.is-open .menu-container {
        color: ${(props) => props?.theme?.colors?.foreground?.text};
        border: none;
        box-shadow: inset 0 0 0 1px ${(props) => props?.theme?.colors?.primary?.base};
    }

    &.is-invalid .menu-container {
        border: none;
        box-shadow: inset 0 0 0 2px ${(props) => props?.theme?.colors?.error?.base};
    }

    .miniSubCatList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .list-items {
                display: grid;
                width: 100%;
                grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
                gap: 16px;

                & > div {
                    height: 49px;
                    padding: 0px 16px;
                    border-radius: 8px;
                    border-width: 2px;
                    box-shadow: 0px 2px 6px 0px rgba(19, 33, 34, 0.08);

                    & > label {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                    & > label > div {
                        display: flex;
                        flex-direction: column;
                    }

                    &:not(.is-checked) {
                        border-color: #e0e2e2;
                    }

                    .checkbox-label {
                        position: relative;
                        top: 0px;
                        text-transform: capitalize;
                        font-family: 'Objektiv Mk2 Bold';
                        font-size: 14px;
                        letter-spacing: 0px;
                        margin-left: 0px;
                    }
                }
            }
            .check {
                display: none;
            }
        }
    }
`;

const MenuItemStyled = styled(MenuItem)`
    font-family: Objektiv Mk2;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0px 10px 16px;
    align-items: center;
    background-color: ${(props) => (props.checked ? '#EAECF0' : 'transparent')};
    border: 1px solid #cfd0d0;
    border-radius: 2px;
`;

const MenuContainer = styled.div`
    max-height: 260px;
    overflow-y: auto;

    .MuiPaper-root {
        width: 100% !important;
    }
`;

const VendorCategoryMultiSelect = (props) => {
    const {
        selectedCategoryIds,
        setSelectedCategoryIds,
        selectedCategoryDetail,
        setSelectedCategoryDetail,
        categoryListItems,
    } = props;
    const formFieldName = 'VendorCategoryId';
    const providedLabel = 'Select Category';
    const { register, setValue, getValues, formState } = useFormContext();
    const { ref, ...additionalProps } = register(formFieldName);

    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);

    const buttonText = useMemo(
        () =>
            selectedCategoryDetail
                .map((x) => x.label)
                .join('/ ')
                .trim(),
        [selectedCategoryDetail]
    );

    const getClassNames = () => {
        const isInvalid = formState ? formState.errors[formFieldName] : false;
        const names = [
            selectedCategoryIds?.length > 0 ? 'is-filled' : '',
            isOpen ? 'is-open' : '',
            isInvalid ? 'is-invalid' : '',
        ].filter((x) => !!x);
        return names.join(' ');
    };
    const renderItem = (item) => {
        let values = [...selectedCategoryIds];
        let selectedCatDetails = [...selectedCategoryDetail];
        const checked = values?.findIndex((id) => id === item.id) > -1;

        const clicked = (e) => {
            const subcategories = getValues('VendorSubCategoryIds');
            if (checked) {
                values = values.filter((x) => x !== item.id);
                selectedCatDetails = selectedCatDetails.filter((x) => x.id !== item.id);
                delete subcategories[item.id];
                setValue('VendorSubCategoryIds', subcategories);
            } else {
                selectedCatDetails.push(item);
                values?.push(item.id);
                setValue('VendorSubCategoryIds', {
                    ...subcategories,
                    [item.id]: [],
                });
            }
            setSelectedCategoryIds(values);
            setSelectedCategoryDetail(selectedCatDetails);
            setValue(formFieldName, values?.join(','));

            e.preventDefault && e.preventDefault();
            e.stopPropagation && e.stopPropagation();
            return false;
        };

        return (
            <MenuItemStyled key={item.label} value={item.label} onClick={clicked} checked={checked}>
                <span>{item.label}</span>
                <ToggleSwitch checked={checked} onChange={clicked} />
            </MenuItemStyled>
        );
    };
    return (
        <div>
            <MultiSelectStyled className={getClassNames()}>
                <BorderLabelContainer
                    className="menu-container"
                    label={selectedCategoryIds?.length > 0 ? providedLabel : null}
                >
                    <ButtonStyled
                        type="button"
                        role="button"
                        ref={buttonRef}
                        onClick={() => setIsOpen(!isOpen)}
                        // {...register(formFieldName)}
                        {...additionalProps}
                    >
                        {buttonText || providedLabel}
                        <PearlIcon
                            className="icon"
                            iconName={isOpen ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
                            size={IconSizes.SM}
                        ></PearlIcon>
                    </ButtonStyled>
                </BorderLabelContainer>
                <Menu
                    open={isOpen}
                    anchorEl={buttonRef.current}
                    PaperProps={{
                        style: {
                            width: buttonRef.current ? buttonRef.current.offsetWidth : 'auto',
                        },
                    }}
                    onClose={() => setIsOpen(false)}
                >
                    <MenuContainer onMouseLeave={() => setIsOpen(false)}>
                        {categoryListItems?.map(renderItem)}
                    </MenuContainer>
                </Menu>
            </MultiSelectStyled>
        </div>
    );
};

export { VendorCategoryMultiSelect };
