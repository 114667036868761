import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CityStateAutoComplete } from 'shared/components/CityStateAutoComplete/CityStateAutoComplete';
import { ToggleButtonGroup, ToggleButton, IconName } from 'dbi-pearl-ui-kit';
import { FormRow, PearlError } from 'framework';
import { formFieldNames } from '../Functions/ServiceAreaForm.Functions';
import { TravelOptionsStyled } from './TravelOptions.styled';
import { WebstoreAddressService } from 'framework';
import { Slider } from '@mui/material';
import { Circle, GoogleMap } from '@react-google-maps/api';
import { GeocodeService } from 'lib/apis/GeocodeService';

const silverTheme = [
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#d3d3d3',
            },
        ],
    },
    {
        featureType: 'transit',
        stylers: [
            {
                color: '#808080',
            },
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#b3b3b3',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ffffff',
            },
            {
                weight: 1.8,
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#d7d7d7',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ebebeb',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
            {
                color: '#a7a7a7',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#efefef',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#696969',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#737373',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#d6d6d6',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {},
    {
        featureType: 'poi',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#dadada',
            },
        ],
    },
];

const TravelOptions = (props) => {
    const {
        mapFunctions,
        travelOptionSelected,
        travelOptionsClickHandler,
        physicalStore,
        localTravel,
        setServiceCityState,
        addressLatLng,
        placeId,
    } = props;
    const { travelOptions } = WebstoreAddressService;
    const { t } = useTranslation();
    const { watch, setValue, clearErrors } = useFormContext();
    const [svcAreaQueryResult, setSvcAreaQueryResult] = useState('');
    const [hideServiceAreaAutoCompleteOptions, setHideServiceAreaAutoCompleteOptions] =
        useState(true);
    const [latLng, setLatLng] = useState({
        lat: addressLatLng && !isNaN(addressLatLng.lat) ? parseFloat(addressLatLng.lat) : 40.712776,
        lng:
            addressLatLng && !isNaN(addressLatLng.lng) ? parseFloat(addressLatLng.lng) : -74.005974, //default to New York, NY
    });

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zoom, setZoom] = useState(13);
    const [miles, setMiles] = useState(() => {
        const initialMiles = watch(formFieldNames.TravelDistance);
        return initialMiles !== undefined && initialMiles !== null ? initialMiles : 25;
    });

    const mileRange = [
        { value: 25 },
        { value: 50 },
        { value: 100 },
        { value: 250 },
        { value: 500 },
    ];
    const convertMilesToMeters = (miles) => miles * 1609.34;

    const radiusInMeters = convertMilesToMeters(miles);
    const innerCircleRadius = radiusInMeters / 15;

    const handleSliderChange = (event, newValue) => {
        setMiles(newValue);
        setValue(formFieldNames.TravelDistance, newValue);
    };
    const [mapLoaded, setMapLoaded] = useState(false);
    useEffect(() => {
        let isMounted = true;
        if (addressLatLng && addressLatLng.lat && addressLatLng.lng) {
            if (isMounted) {
                setLatLng({
                    lat: parseFloat(addressLatLng.lat),
                    lng: parseFloat(addressLatLng.lng),
                });
            }
        }
        return () => {
            isMounted = false;
        };
    }, [addressLatLng]);

    const loadPlacesScript = () => {
        let isMounted = true;
        if (window?.google?.maps) {
            const service = new window.google.maps.places.PlacesService(
                document.createElement('div')
            );

            service.getDetails({ placeId: placeId }, (place, status) => {
                if (isMounted) {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setMapLoaded(true);
                    } else {
                        console.error('Error fetching place details:', status);
                    }
                }
            });
        }
        return () => {
            isMounted = false;
        };
    };
    useEffect(() => {
        if (placeId) {
            loadPlacesScript();
        }
    }, [placeId]);
    useEffect(() => {
        const storedMiles = watch(formFieldNames.TravelDistance);
        if (storedMiles === undefined || storedMiles === null) {
            setValue(formFieldNames.TravelDistance, 25);
            setMiles(25);
        } else {
            setMiles(storedMiles);
        }
    }, [miles]);
    useEffect(() => {
        if (miles === 25) setZoom(8);

        if (miles === 50) setZoom(7);

        if (miles === 100) setZoom(6);

        if (miles === 250) setZoom(4);

        if (miles === 500) setZoom(3);
    }, [miles]);

    const { setMapZoom } = mapFunctions || {};
    const serviceAreaInput = watch(formFieldNames.ServiceArea);

    const mapZoomOnTravelChange = (optionSelected, setMapZoom) => {
        if (optionSelected === travelOptions.Globally) {
            setMapZoom(2);
        } else {
            setMapZoom(4);
        }
    };

    const geoCodeService = GeocodeService();

    useEffect(() => {
        if (serviceAreaInput?.length > 0) {
            setHideServiceAreaAutoCompleteOptions(false);
            setValue(formFieldNames.ServiceArea, serviceAreaInput);
            setValue(formFieldNames.ServiceAreaCity, serviceAreaInput.split(',')?.[0]?.trim());
            setValue(formFieldNames.ServiceAreaState, serviceAreaInput.split(',')?.[1]?.trim());
            clearErrors(formFieldNames.ServiceArea);
        } else {
            setHideServiceAreaAutoCompleteOptions(true);
        }
    }, [serviceAreaInput]);

    useEffect(() => {
        if (serviceAreaInput && serviceAreaInput.trim() !== '') {
            const commaCount = serviceAreaInput.match(/,/g)?.length;
            if (commaCount === 3) {
                setCity(serviceAreaInput.split(',')?.[2]?.trim());
                setState(serviceAreaInput.split(',')?.[3]?.trim());
            } else {
                setCity(serviceAreaInput.split(',')?.[3]?.trim());
                setState(serviceAreaInput.split(',')?.[4]?.trim());
            }
            if (commaCount === 1) {
                setCity(serviceAreaInput.split(',')?.[0]?.trim());
                setState(serviceAreaInput.split(',')?.[1]?.trim());
            }
            if (commaCount === 2) {
                setCity(serviceAreaInput.split(',')?.[1]?.trim());
                setState(serviceAreaInput.split(',')?.[2]?.trim());
            }
        }
    }, [serviceAreaInput]);
    const updateLatLng = useCallback(
        (newLat, newLng) => {
            if (latLng.lat !== newLat || latLng.lng !== newLng) {
                setLatLng({ lat: newLat, lng: newLng });
            }
        },
        [latLng]
    );

    useEffect(() => {
        if (city && state) {
            geoCodeService
                .getGeocodeByAddress(city, state)
                .then((res) => {
                    if (res && res.latitude && res.longitude) {
                        updateLatLng(res.latitude, res.longitude);
                    }
                })
                .catch((err) => console.error('Geocoding failed:', err));
        }
    }, [city, state, updateLatLng]);

    useEffect(() => {
        if (svcAreaQueryResult) {
            setHideServiceAreaAutoCompleteOptions(false);
            const city = svcAreaQueryResult?.addressComponents?.find((comp) =>
                comp.types.includes('locality')
            )?.long_name;
            const state = svcAreaQueryResult?.addressComponents?.find((comp) =>
                comp.types.includes('administrative_area_level_1')
            )?.short_name;

            if (city && state) {
                setServiceCityState(setValue, city, state);
            }
        } else {
            setHideServiceAreaAutoCompleteOptions(true);
        }
    }, [svcAreaQueryResult]);

    const travelOptionsClick = (optionSelected) => {
        travelOptionsClickHandler(optionSelected);
        if (optionSelected === travelOptions.Locally) {
            setMiles(25);
            setValue(formFieldNames.TravelDistance, 25);
            setValue(formFieldNames.ServiceArea, '');
        }
        if (mapFunctions && !physicalStore) {
            mapZoomOnTravelChange(optionSelected, setMapZoom);
        }
    };
    function valuetext(value) {
        return `${value}°C`;
    }

    const memoizedOptions = useMemo(
        () => ({
            styles: silverTheme,
            disableDefaultUI: true,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: true,
            fullscreenControl: true,
        }),
        []
    );

    const renderMap = () => {
        if (!mapLoaded || !latLng) {
            return <div>Loading map...</div>;
        }

        return (
            <GoogleMap
                center={{
                    lat: parseFloat(latLng.lat),
                    lng: parseFloat(latLng.lng),
                }}
                zoom={zoom}
                options={memoizedOptions}
                mapContainerClassName="mapStyles"
                onLoad={() => setMapLoaded(true)}
            >
                <Circle
                    center={{
                        lat: parseFloat(latLng.lat),
                        lng: parseFloat(latLng.lng),
                    }}
                    radius={radiusInMeters}
                    options={{
                        fillColor: '#e41395',
                        fillOpacity: 0.1,
                        strokeColor: '#e41395',
                        strokeOpacity: 1,
                        strokeWeight: 3,
                    }}
                />
                <Circle
                    center={{
                        lat: parseFloat(latLng.lat),
                        lng: parseFloat(latLng.lng),
                    }}
                    radius={innerCircleRadius}
                    options={{
                        fillColor: '#FFFFFF',
                        fillOpacity: 0.6,
                        strokeColor: '#e41395',
                        strokeOpacity: 1,
                        strokeWeight: 3,
                    }}
                />
            </GoogleMap>
        );
    };
    return (
        <TravelOptionsStyled>
            <FormRow
                columns={[
                    <div key={0}>
                        <ToggleButtonGroup
                            groupName={formFieldNames.TravelOption}
                            exclusive={true}
                            onChange={() => {}}
                            selectedGroupItems={[travelOptionSelected]}
                        >
                            <ToggleButton
                                value={travelOptions.Locally}
                                label={t('Forms.ServiceArea.Local')}
                                data-testid="travel-locally"
                                startIcon={IconName.MAP_MARKER_OUTLINE}
                                iconName={IconName.CHECKMARK}
                                onClick={() => travelOptionsClick(travelOptions.Locally)}
                            ></ToggleButton>
                            <ToggleButton
                                startIcon={IconName.AIRPLANE}
                                value={travelOptions.Nationally}
                                label={t('Forms.ServiceArea.National')}
                                iconName={IconName.CHECKMARK}
                                data-testid="travel-nationally"
                                onClick={() => travelOptionsClick(travelOptions.Nationally)}
                            />
                            <ToggleButton
                                startIcon={IconName.WEB}
                                value={travelOptions.Globally}
                                label={t('Forms.ServiceArea.Global')}
                                iconName={IconName.CHECKMARK}
                                data-testid="travel-globally"
                                onClick={() => travelOptionsClick(travelOptions.Globally)}
                            />
                        </ToggleButtonGroup>
                        <PearlError name={formFieldNames.TravelOption} />
                    </div>,
                ]}
            />
            {localTravel && (
                <>
                    <div className="cityStateStyles">
                        <p className="option-question" style={{ marginBottom: '16px' }}>
                            <b>{t('Forms.ServiceArea.PrimaryMarketQuestion')}</b>
                        </p>
                        <CityStateAutoComplete
                            setSearchResult={setSvcAreaQueryResult}
                            hideOptions={hideServiceAreaAutoCompleteOptions}
                            startIcon={null}
                            fieldName={formFieldNames.ServiceArea}
                        />
                        <PearlError name={formFieldNames.ServiceArea} />
                    </div>
                    <div className="sliderMapContainer">
                        <div className="milesContainer">
                            <p className="travelHeading">
                                {t('Forms.ServiceArea.TravelSelection')}
                            </p>
                            <div className="milesWithSlider">
                                <p className="milesText">
                                    <b>{miles} MILES</b>
                                </p>
                                <div className="sliderWithDots" data-testid="TravelDistance">
                                    <div className="sliderDot leftside"></div>
                                    <Slider
                                        aria-label="Miles slider"
                                        value={miles}
                                        getAriaValueText={valuetext}
                                        valueLabelDisplay="off"
                                        step={null}
                                        onChange={handleSliderChange}
                                        marks={mileRange}
                                        min={25}
                                        max={500}
                                        className="sliderStyles"
                                        data-testid="slider"
                                    />
                                    <div className="sliderDot rightside"></div>
                                </div>
                            </div>
                        </div>
                        {renderMap()}
                    </div>
                </>
            )}
        </TravelOptionsStyled>
    );
};

export { TravelOptions };
