/* eslint-disable no-console, no-unused-vars */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { VendorPlanFormStyled } from './VendorPlanForm.styled';
import PearlForm from 'vendor/components/forms/PearlForm';
import { VendorAccountSetupStepLayout, VendorAccountSetupStepFooter } from 'framework';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/NewWebstoreSetupContext';
import { useTealium, buildWebstoreSetupStepPageData } from '../../../../Tealium';
import { useFormContext } from 'react-hook-form';
import { NewVendorWebstoreSetupHeader } from '../NewVendorWebstoreSetupHeader';
import PickYourPlanSection from 'vendor/components/Webstore/PickYourPlanSection/PickYourPlanSection';
import { TermsOfServiceService } from 'vendor/lib/apis/TermsOfServiceService';
import { unwrapResponse } from '../../../../lib/apis/utils';
import { PickYourPlanSchemaWrapper } from 'vendor/components/Webstore/PickYourPlanSection/PickYourPlanSchema';
import { useVendorContext } from 'vendor/contexts/VendorContext';

const formName = 'PlanTier';

const Footer = () => {
    const { progress, onBack } = useWebstoreSetupWizard();
    const {
        watch,
        formState: { isDirty },
    } = useFormContext();

    const selectedPlan = watch('SelectedPlan');

    return (
        <VendorAccountSetupStepFooter
            progress={progress}
            handleOnBack={() => onBack()}
            showBack={false}
        />
    );
};

const VendorPlanForm = () => {
    const { link, view } = useTealium();
    const { webstore } = useWebstoreContext();
    const { onSubmit, currentStepCount, totalSteps } = useWebstoreSetupWizard();
    const { t } = useTranslation();
    const termsOfServiceApi = TermsOfServiceService();
    const { vendor } = useVendorContext();

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const defaultValue = webstore.SelectedPlan ?? 'planthree';

    const formOptions = {
        mode: 'onChange',
        defaultValues: { SelectedPlan: defaultValue },
    };

    const handleSubmit = async (data) => {
        data = {
            SelectedPlan: data.SelectedPlan,
        };

        const tealiumData = {
            event_name: 'setup-step-advance-click',
            setup_step_number: 1,
            setup_step_total_step_count: totalSteps,
            setup_step_name: t(`Forms.${formName}.StepName`),
            setup_step_form_data: data,
            setup_step_patch_data: data,
            webstore_id: webstore.Id,
        };

        link(tealiumData);
        const res = await termsOfServiceApi.putAcceptTermsOfService(vendor.id);
        const tosData = unwrapResponse(res);
        if (tosData?.success) {
            vendor.updateVendor({ tosAccepted: true });
        }
        onSubmit(data);
    };

    const PickYourPlanSchema = PickYourPlanSchemaWrapper();

    return (
        <PearlForm
            StyledForm={VendorPlanFormStyled}
            formName={formName}
            formOptions={formOptions}
            onSubmit={(data) => handleSubmit(data)}
            validationSchema={PickYourPlanSchema}
        >
            <VendorAccountSetupStepLayout
                header={
                    <NewVendorWebstoreSetupHeader
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps - 1}
                    />
                }
                formComponent={<PickYourPlanSection defaultPlanValue={defaultValue} />}
                footer={<Footer />}
            />
        </PearlForm>
    );
};

export { VendorPlanForm };
