import styled from 'styled-components';

const MediaSectionEditStyled = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;

    .save-button {
        display: none;
    }
    h2 {
        text-transform: none;
    }
`;

export { MediaSectionEditStyled };
