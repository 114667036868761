import RouteGuard from './route-guards/RouteGuard';

import { loadAutocomplete, loadAutocompleteService } from './lib/GooglePlacesAutocompleteService';
import { useOnChangeDebounce } from './lib/DebounceService';
import { ask } from './lib/ModalService';
import { RealtimeClient } from './lib/apis/RealtimeClient';
import { navigation } from './constants/navigation';
import {
    useScreenSize,
    useWindowSize,
    useWindowMonitor,
    SCREENSIZEMODES,
} from './lib/ScreenSizeService';
import {
    usePearlMediaQuery,
    useIsScreenSmall,
    useIsScreenTablet,
    useIsScreenMedium,
    useIsScreenLarge,
} from './hooks/PearlMediaQuery';

import { PearlApiClient, PearlApps } from './lib/apis/PearlApiClient';

import { FormRow } from './components/forms/FormRow/FormRow';
import { PearlError } from './components/forms/PearlError';
import PearlForm, { FormType } from './components/forms/PearlForm';
import PearlUnsavedChangesAlert from './components/PearlUnsavedChangesAlert/PearlUnsavedChangesAlert';
import { PearlToast } from './components/PearlToast/PearlToast';

import WebstoreBasics from './components/Webstore/WebstoreBasics/WebstoreBasics';
import WebstoreNavigation from './components/Webstore/WebstoreNavigation/WebstoreNavigation';
import WebstoreHeader from './components/Webstore/WebstoreHeader/WebstoreHeader';
import WebstoreFooter from './components/Webstore/WebstoreFooter/WebstoreFooter';
import WebstoreMobileHeader from './components/Webstore/WebstoreMobileHeader/WebstoreMobileHeader';
import WebstoreSection from './components/Webstore/WebstoreSection/WebstoreSection';
import { WebstoreSectionTypes } from './constants/WebstoreSectionTypes';
import {
    scrollWithOffset,
    scrollByWithOffset,
    activeAnchorObserver,
    useScrollToConditionallyDisplayedElement,
    watchRef,
} from './components/Webstore/WebstoreHelpers/WebstoreHelpers';
import WebstoreGallery from './components/Webstore/WebstoreGallery/WebstoreGallery';
import { ModalImageGallery } from './components/Webstore/ModalImageGallery/ModalImageGallery';
import { WebstorePackages } from './components/Webstore/WebstorePackages/WebstorePackages';
import {
    WebstorePackage,
    WebstorePackageSizes,
} from './components/Webstore/WebstorePackage/WebstorePackage';
import { WebstoreAwards } from './components/Webstore/WebstoreAwards/WebstoreAwards';
import WebstorePreferredPartners from './components/Webstore/WebstorePreferredPartners/WebstorePreferredPartners';
import { WebstoreFaqs } from './components/Webstore/WebstoreFaqs/WebstoreFaqs';

import { usePearlModal, PearlModalProvider, PearlModalSizes } from './contexts/PearlModalContext';

import { useUnsavedChangesPrompt, useBlocker } from './contexts/PearlPromptContext';

import { usePearlToast, PearlToastProvider } from './contexts/PearlToastContext';

import { NewTabLink } from './components/utility/NewTabLink';

import usePearlUpdateFieldAlert from './contexts/PearlUpdateFieldAlertContext';

import FlaggedImage from './components/utility/FlaggedImage/FlaggedImage';
import { moderateImage, moderateImages } from './lib/ImageModerationService/ImageModerationService';

import { AmenitiesServices } from './components/Webstore/AmenitiesServices/AmenitiesServices';
import { AboutSection } from './components/Webstore/AboutSection/AboutSection';
import { AvailabilityCalendar } from './components/Webstore/AvailabilityCalendar/AvailabilityCalendar';
import { WebstoreImageSlider } from './components/Webstore/WebstoreImageSlider/WebstoreImageSlider';
import { GoogleReviews } from './components/Webstore/WebstoreReviews/GoogleReviews/GoogleReviews';
import { loadScript } from './lib/GooglePlacesScriptLoader';
import { socialLinksPrefixes } from './constants/socialLinksPrefixes';
import { AccountSetupStepCounter } from './components/AccountSetup/AccountSetupStepCounter/AccountSetupStepCounter';
import { AccountSetupStepFooter } from './components/AccountSetup/AccountSetupStepFooter/AccountSetupStepFooter';
import { VendorAccountSetupStepFooter } from './components/AccountSetup/VendorAccountSetupStepFooter/VendorAccountSetupStepFooter';
import { AccountSetupStepGraphic } from './components/AccountSetup/AccountSetupStepGraphic/AccountSetupStepGraphic';
import { AccountSetupStepLayout } from './components/AccountSetup/AccountSetupStepLayout/AccountSetupStepLayout';
import { VendorAccountSetupStepLayout } from './components/AccountSetup/VendorAccountSetupStepLayout/VendorAccountSetupStepLayout';
import { PriceTierTile } from './components/Webstore/PriceTierTile/PriceTierTile';
import { PriceTierTileNew } from './components/Webstore/PriceTierTile/PriceTierTileNew';
import PickYourPlanTile from './components/Webstore/PickYourPlanTile/PickYourPlanTile';
import { WebstorePriceTiers } from './constants/WebstorePriceTiers';
import { SectionHeader } from './components/Webstore/SectionHeader/SectionHeader';

import { ChatSources } from './components/Chat/ChatSources';
import { ConversationWindow } from './components/Chat/ConversationWindow/ConversationWindow';
import { ConversationList } from './components/Chat/ConversationList/ConversationList';
import { ConversationCard } from './components/Chat/ConversationCard/ConversationCard';
import { ConversationHeader } from './components/Chat/ConversationHeader/ConversationHeader';
import { EmptyInbox } from './components/Chat/EmptyInbox/EmptyInbox';

import { CircularProgress } from './components/utility/Progress/CircularProgress';
import { LinearProgress } from './components/utility/Progress/LinearProgress';

import { ResponsiveTextElement } from './components/Elements/ResponsiveTextElement/ResponsiveTextElement';
import { PhoneNumberInput } from './components/PhoneNumberInput/PhoneNumberInput';
import { categoryExternalIds } from './constants/categories';
import { CappedCarousel } from './components/CappedCarousel/CappedCarousel';
import { addressFieldToGoogleResponseMapping } from './lib/WebstoreAddressService/WebstoreAddressService';

import { en } from './constants/translations/english';
import { es } from './constants/translations/spanish';

import { installTealium, useTealium, TealiumProvider } from './hooks/Tealium';

import { supportsHEVCAlpha } from './lib/BrowserUtility';

import * as WebstoreAddressService from './lib/WebstoreAddressService/WebstoreAddressService';
import { PhoneNumberService } from './lib/PhoneNumberService/PhoneNumberService';

const translations = {
    en,
    es,
};

export {
    RouteGuard,
    loadAutocomplete,
    loadAutocompleteService,
    useOnChangeDebounce,
    // parseCognitoUser,
    ask,
    RealtimeClient,
    PearlApiClient,
    PearlApps,
    FormRow,
    PearlError,
    PearlForm,
    FormType,
    PearlToast,
    PearlUnsavedChangesAlert,
    WebstoreBasics,
    WebstoreNavigation,
    WebstoreHeader,
    WebstoreFooter,
    WebstoreMobileHeader,
    WebstoreSection,
    WebstoreSectionTypes,
    scrollWithOffset,
    scrollByWithOffset,
    watchRef,
    usePearlModal,
    PearlModalProvider,
    PearlModalSizes,
    useUnsavedChangesPrompt,
    useBlocker,
    usePearlToast,
    PearlToastProvider,
    usePearlUpdateFieldAlert,
    NewTabLink,
    FlaggedImage,
    moderateImages,
    moderateImage,
    navigation,
    WebstoreGallery,
    ModalImageGallery,
    useScreenSize,
    useWindowSize,
    useWindowMonitor,
    SCREENSIZEMODES,
    usePearlMediaQuery,
    useIsScreenSmall,
    useIsScreenTablet,
    useIsScreenMedium,
    useIsScreenLarge,
    AmenitiesServices,
    AboutSection,
    AvailabilityCalendar,
    WebstoreImageSlider,
    WebstorePackage,
    WebstorePackageSizes,
    WebstorePackages,
    WebstoreAwards,
    WebstorePreferredPartners,
    WebstoreFaqs,
    SectionHeader,
    socialLinksPrefixes,
    translations,
    activeAnchorObserver,
    useScrollToConditionallyDisplayedElement,
    GoogleReviews,
    loadScript,
    AccountSetupStepCounter,
    AccountSetupStepFooter,
    VendorAccountSetupStepFooter,
    AccountSetupStepGraphic,
    AccountSetupStepLayout,
    VendorAccountSetupStepLayout,
    PriceTierTile,
    PriceTierTileNew,
    PickYourPlanTile,
    WebstorePriceTiers,
    ChatSources,
    ConversationWindow,
    ConversationList,
    ConversationCard,
    ConversationHeader,
    EmptyInbox,
    CircularProgress,
    LinearProgress,
    ResponsiveTextElement,
    PhoneNumberInput,
    installTealium,
    useTealium,
    TealiumProvider,
    supportsHEVCAlpha,
    WebstoreAddressService,
    categoryExternalIds,
    CappedCarousel,
    addressFieldToGoogleResponseMapping,
    PhoneNumberService,
};
