import styled from 'styled-components';

const VendorBasicDetailsFormStyled = styled.form`
    display: flex;
    height: 100%;
    .phoneNumber {
        position: relative;
        top: -5px;
    }
    .acceptTerms {
        margin-bottom: 0px;
    }
    .agreeTerms {
        margin-top: 13px;
        color: #424d4e;
    }
    .agreement {
        display: flex;
        margin: 5px 0 0;
        & > label {
            padding-left: 0;
            display: flex;
        }
    }
    .radio-label {
        margin-right: 36px;
    }
`;

const ModalContainer = styled.div`
    width: 672px;
    height: 450px;
    background-color: #fff;
    padding: 44px 44px 0px 44px;
    .infoStyle {
        padding-top: 3.5px;
        padding-left: 5px;
    }
    .iconWithLabel {
        display: flex;
        gap: 16px;
        @media (max-width: 500px) {
            gap: 8px;
            padding: 0px;
        }
    }
    .verificationCode {
        margin-top: 16px;
        @media (max-width: 500px) {
            margin-left: 12px;
        }
        @media (max-width: 380px) {
            width: 300px;
        }
    }
    .verifyBtnStyle {
        display: flex;
        justify-content: right;
        margin-top: 64px;
        margin-bottom: 12px;
    }
    .labelStyle {
        padding-top: 6px;
        color: ${(props) => props.theme.colors.primary.base};
        font-family: 'Objektiv Mk2 Bold';
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 1.65px;
        text-transform: uppercase;
    }
    .iconStyle {
        color: #132122;
    }
    .textStyle {
        color: black;
        margin-bottom: 16px;
    }
    .VerifyBtnStyle {
        padding: 0px 75px;
    }
    .emailError {
        color: #de1717;
        margin-bottom: 10px;
    }
    .resendButton-true {
        display: flex;
        border-bottom: 2px solid #717777;
        width: fit-content;
    }
    .resendButton-false {
        display: flex;
        border-bottom: 2px solid #132122;
        width: fit-content;
    }
    .isResendDisabled-true {
        color: #717777;
        margin-bottom: 10px;
    }
    .isResendDisabled-false {
        color: #132122;
        cursor: pointer;
        margin-bottom: 10px;
    }
    @media (max-width: 815px) {
        width: 526px;
        height: 390px;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (max-width: 450px) {
        width: 350px;
        height: 550px;
        padding: 10px;
    }
    @media (max-width: 380px) {
        width: 300px;
        height: 600px;
    }
`;

const WelcomeScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 330px;
    .checkmark {
        color: green;
    }
    .messageBox {
        text-align: center;
    }
    @media (max-width: 815px) {
        padding: 25px;
    }
`;

const FormComponent = styled.div`
    margin-bottom: 100px;
    .logoStyle {
        padding-bottom: 0px;
    }
    @media (max-width: 500px) {
        width: 100%;
        margin-bottom: 80px;
    }
    .passwordSection {
        display: flex;
        @media (max-width: 815px) {
            gap: 0px;
            display: flex;
            flex-direction: column;
        }
    }
    .passwordValidation {
        margin-top: 8px;
    }
    .section-2 {
        width: 817px;
        @media (max-width: 1024px) {
            width: 817px;
        }
        @media (max-width: 815px) {
            width: 100%;
            flex-direction: row;
        }
    }
    .section-3 {
        width: 817px;
        @media (max-width: 1024px) {
            width: 817px;
        }
        @media (max-width: 815px) {
            width: 100%;
            flex-direction: row;
        }
        @media (max-width: 500px) {
            width: 100%;
            flex-direction: column;
        }
    }
    .section-1 {
        width: 400px;
        @media (max-width: 815px) {
            width: 100%;
        }
    }
    .sectionTitle {
        color: ${(props) => props.theme.colors.primary.base};
        font-family: 'Objektiv Mk2 Bold';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 16px;
    }
    .termsTitle {
        color: ${(props) => props.theme.colors.primary.base}
        font-family: 'Objektiv Mk2 Bold';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 16px;
        margin-top: 24px;
    }
    .CreatePasswordSection {
        color: ${(props) => props.theme.colors.primary.base};
        font-family: 'Objektiv Mk2';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-top: 20px;
    }
    .addLogo {
        display: flex;
        padding-bottom: 11px;
        gap: 10px;
        @media (max-width: 500px) {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
    .passwordInstructionStyle {
        display: flex;
        gap: 8px;
        margin-top: 8px;
    }
    .passwordTextStyle {
        color: #0a5151;
        font-family: 'Objektiv Mk2';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        margin: 0px;
    }
    .passwordStrength-weak {
        position: relative;
        top: -1px;
        left: -4px;
        color: #920505;
        font-family: 'Objektiv Mk2';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
    }

    .passwordStrength-medium {
        position: relative;
        top: -1px;
        left: -4px;
        color: #920505;
        font-family: 'Objektiv Mk2';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
    }
    .passwordStrength-strong {
        position: relative;
        top: -1px;
        left: -4px;
        color: #0a5151;
        font-family: 'Objektiv Mk2';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
    }
    .eyeIconPosition {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }
    .signUpError {
        color: #de1717;
    }
    .alreadyHaveAccount {
        margin: 0px;
    }
    .passwordInstructionPosition {
        position: relative;
    }
    .phoneNumberStyle {
        margin-top: 10px;
    }
`;

const ModalTitle = styled.h4`
    text-transform: none;
    margin-top: 0px;
    margin-bottom: 40px;
    color: #132122;
    line-height: 36px;
    font-size: 24px;
`;
const ModalInput = styled.input`
    width: 432px;
    height: 52px;
    @media (max-width: 768px) {
        width: 70%;
    }
    padding-left: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
`;

const VerificationResendLink = styled.a`
    font-family: 'Objektiv Mk2 Bold';
    font-size: 11px;
    font-style: normal;
    line-height: 15px;
    font-weight: 700;
    letter-spacing: 1.65px;
    text-transform: uppercase;
    margin-top: 5px;
    text-decoration: none;
`;

const WelcomeTitle = styled.h2`
    margin-bottom: 24px;
    text-transform: none;
    color: #333;
`;

const WelcomeSubtitle = styled.h4`
    text-transform: none;
`;

export {
    VendorBasicDetailsFormStyled,
    ModalContainer,
    WelcomeScreenContainer,
    FormComponent,
    ModalTitle,
    ModalInput,
    VerificationResendLink,
    WelcomeTitle,
    WelcomeSubtitle,
};
