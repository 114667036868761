/* eslint-disable eqeqeq */
import styled from 'styled-components';
import { formatPrice } from 'framework/constants/WebstorePlanTiers';
import { usePromoCode } from '../Checkout/PromoCodeContext';
import { StorageService } from 'lib/StorageService';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useState } from 'react';
const OverviewLabel = styled.p`
     {
        font-size: 14px;
        margin: 0px;
        @media (max-width: 815px) {
            font-size: 12px;
        }
    }
`;
const OverviewTitle = styled.h2`
     {
        font-size: 16px;
        text-transform: capitalize;
        line-height: 22.4px;
        margin: 0px;
    }
`;
const CheckoutOverViewSectionStyled = styled.div`
    max-width: 432px;
    display: flex;
    @media (max-width: 815px) {
        max-width: 100% !important;
    }
    .checkoutDetails {
        display: flex;
        width: 100%;
        @media (max-width: 815px) {
            width: 100% !important;
            gap: 24px;
        }
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 32px;

        @media (min-width: 815px) {
            border-left: 1px solid #cfd0d0;
            padding-left: 24px;
        }
    }
    #Billing {
        border-radius: 1000px;
        font-weight: 700 !important;
        font-size: 14px;
        padding-right: 30px;
    }
    .Billing-container {
        width: 184px;
        .p4 {
            display: none;
        }
    }
    .summaryTitle {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-bottom: 16px;
        @media (max-width: 815px) {
            gap: 16px;
            padding-bottom: 8px;
        }
    }
    .billing {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 202px;
        span {
            font-size: 14px;
        }
    }
    .middleSection {
        display: flex;
        padding-bottom: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        border-bottom: 1px solid #cfd0d0;
    }
    .subtotal {
        display: flex;
        padding-bottom: 16px;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        & > p {
            margin: 0px;
        }
    }
    .paymentDisclaimer {
        & > p {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            margin: 0px;
        }
    }
    .priceContainer {
        display: flex;
        padding-bottom: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        border-bottom: 1px solid #cfd0d0;
    }
`;
const PaymentType = styled.div`
    border-radius: 8px;
    background: var(--Basic-White, #fff);
    box-shadow: 0 2px 6px rgba(19, 33, 34, 0.08);
    cursor: pointer;
    padding: 16px;
    border: ${({ isSelected }) => (isSelected ? '2px solid #E41395' : '1px solid #e0e2e2')};

    .paymentInfoTitle {
        margin: 0;
        font-size: 14px;
        line-height: 21px;
        font-family: 'Objektiv Mk2 Bold';
        color: ${({ isSelected }) => (isSelected ? '#000' : '#424D4E')};
        font-weight: ${({ isSelected }) => (isSelected ? 700 : 500)};
    }

    .paymentInfoPrice {
        margin: 0;
        font-size: 18px;
        line-height: 27px;
        font-family: 'Objektiv Mk2 Bold';
        color: ${({ isSelected }) => (isSelected ? '#000' : '#424D4E')};
        font-weight: ${({ isSelected }) => (isSelected ? 700 : 500)};
    }

    .paymentInfoSavings {
        color: #132122;
        font-family: 'Objektiv Mk2 Bold';
        text-align: center;
        font-size: 10px;
        line-height: 21px;
        border-radius: 8px;
        background: #f9dfd5;
        padding: 0px 8px;
    }
`;

const CheckoutOverviewSection = ({
    title,
    planValue,
    subtotalTitle,
    totalTitle,
    savingsTitle,
    salesTax,
    taxLabel,
    billingFrequency,
    onBillingFrequencyChange,
}) => {
    const { webstore } = useWebstoreContext();
    const selectedPlan = webstore.SelectedPlan;
    const storageService = StorageService();
    const stripeDataString = storageService.get('stripeData');
    const stripeData = stripeDataString ? JSON.parse(stripeDataString) : [];
    const monthlyPlan = stripeData.filter(
        (item) => item.planTier === selectedPlan && item.billingFrequency === 'month'
    );
    const monthlyPrice = monthlyPlan.length > 0 ? monthlyPlan[0].price : 0;

    const handleYearlyDiscount = (value) => {
        return value ? Math.floor(Number(value) * 0.8) : 0;
    };

    const AnnualDiscount =
        Math.floor(planValue * 12) - parseFloat(12 * Math.floor(planValue * 0.8));
    const [formBillingFrequency, setFormBillingFrequency] = useState(billingFrequency);
    const { promoCodeDiscount } = usePromoCode();
    const calculateSubtotal = (planValue) => {
        if (formBillingFrequency === 1) {
            const subtotalDisplayVal = parseFloat(formBillingFrequency * planValue);
            return subtotalDisplayVal ? subtotalDisplayVal.toFixed(2) : 0;
        } else {
            const subtotalDisplayVal = parseFloat(
                formBillingFrequency * Math.floor(planValue * 0.8)
            );
            return subtotalDisplayVal ? subtotalDisplayVal.toFixed(2) : 0;
        }
    };
    const handleBillingFrequencyChange = (frequency) => {
        onBillingFrequencyChange(frequency);
        if (frequency === 12) {
            setFormBillingFrequency(12);
        } else {
            setFormBillingFrequency(1);
        }
    };
    const billedAmount = (price) => {
        const showBilledAmount = parseFloat(12 * Math.floor(price * 0.8));
        return showBilledAmount ? showBilledAmount.toLocaleString() : 0;
    };
    const calculateTotal = (planValue, salesTax) => {
        let subtotalPrice = calculateSubtotal(planValue);
        const floatTax = parseFloat(salesTax);
        const discount = promoCodeDiscount ? subtotalPrice * (promoCodeDiscount / 100) : null;
        const totalWithTax = parseFloat(subtotalPrice + floatTax - discount).toFixed(2);
        return totalWithTax ? totalWithTax : 0;
    };
    const calculateSavings = (planValue) => {
        if (formBillingFrequency === 12) {
            const discount = promoCodeDiscount
                ? parseFloat(formBillingFrequency * Math.floor(planValue * 0.8)) *
                  (promoCodeDiscount / 100)
                : null;
            return discount ? discount.toFixed(2) : 0;
        }
        if (formBillingFrequency === 1) {
            const discount = promoCodeDiscount
                ? parseFloat(formBillingFrequency * planValue) * (promoCodeDiscount / 100)
                : null;
            return discount ? discount.toFixed(2) : 0;
        }
    };

    return (
        <CheckoutOverViewSectionStyled>
            <div className="checkoutDetails">
                <div className="summaryTitle">
                    <OverviewTitle>{title} Subscription</OverviewTitle>
                    <PaymentType
                        isSelected={formBillingFrequency === 12}
                        role="button"
                        tabIndex="0"
                        onClick={() => handleBillingFrequencyChange(12)}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p className="paymentInfoTitle">Pay Annually</p>
                            <p className="paymentInfoSavings" style={{ margin: '0px' }}>
                                ${AnnualDiscount} Savings
                            </p>
                        </div>
                        <p className="paymentInfoPrice">
                            ${handleYearlyDiscount(monthlyPrice)}/mo*
                        </p>
                        <p style={{ margin: '0px' }}>
                            *Billed upfront for ${billedAmount(monthlyPrice)}
                        </p>
                    </PaymentType>
                    <PaymentType
                        isSelected={formBillingFrequency === 1}
                        role="button"
                        tabIndex="0"
                        onClick={() => handleBillingFrequencyChange(1)}
                    >
                        <p className="paymentInfoTitle">Pay Monthly</p>
                        <p className="paymentInfoPrice">${parseInt(monthlyPrice)}/mo*</p>
                        <p style={{ margin: '0px' }}>
                            *Billed monthly for ${parseInt(monthlyPrice)}
                        </p>
                    </PaymentType>
                </div>
                <div className="priceContainer">
                    <div className="subtotal" style={{ borderBottom: '1px solid #cfd0d0' }}>
                        <OverviewLabel>{subtotalTitle}</OverviewLabel>
                        <p style={{ fontFamily: 'Objektiv Mk2 Bold', fontSize: '14px' }}>
                            ${formatPrice(calculateSubtotal(planValue))}
                        </p>
                    </div>
                    {promoCodeDiscount && (
                        <div className="subtotal" style={{ borderBottom: '1px solid #cfd0d0' }}>
                            <OverviewLabel>{savingsTitle}</OverviewLabel>
                            <p style={{ fontFamily: 'Objektiv Mk2 Bold', fontSize: '14px' }}>
                                -${formatPrice(calculateSavings(planValue))}
                            </p>
                        </div>
                    )}
                    {/* uncomment the Taxes section when we apply Tax
                    <div className="subtotal" style={{ borderBottom: '1px solid #cfd0d0' }}>
                        <OverviewLabel>{taxLabel}</OverviewLabel>
                        <p style={{ fontFamily: 'Objektiv Mk2 Bold', fontSize: '14px' }}>
                            ${parseFloat(salesTax).toFixed(2)}
                        </p>
                    </div> */}
                    <div className="subtotal" style={{ paddingBottom: '0px' }}>
                        <OverviewLabel>{totalTitle}</OverviewLabel>
                        <p
                            data-testid={'totalSum'}
                            style={{ fontFamily: 'Objektiv Mk2 Bold', fontSize: '18px' }}
                        >
                            ${formatPrice(calculateTotal(planValue, salesTax))}
                        </p>
                    </div>
                </div>
            </div>
        </CheckoutOverViewSectionStyled>
    );
};

export { CheckoutOverviewSection };
