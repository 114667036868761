import styled from 'styled-components';

const SectionHeaderStyled = styled.div`
    h3 {
        display: flex;
        gap: 6px;
        word-break: break-word;
        text-transform: none;

        & > span {
            position: relative;
            top: 2px;
        }
    }

    .banner {
        margin-bottom: 16px;
    }
`;

export { SectionHeaderStyled };
