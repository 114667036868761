import styled from 'styled-components';

const ServiceAreaSectionEditStyled = styled.section`
    .BusinessAddress-container,
    .ServiceAddress-container {
        border-radius: 8px;
    }
`;

export { ServiceAreaSectionEditStyled };
