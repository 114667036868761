import * as Yup from 'yup';
import i18n from 'i18n';
import { formFieldNames } from './ServiceAreaForm';
import { WebstoreAddressService } from 'framework';
const { travelOptions } = WebstoreAddressService;

const serviceAreaFormSchema = Yup.object()
    .shape({
        [formFieldNames.OnlineStore]: Yup.bool().nullable(),
        [formFieldNames.ShippingRange]: Yup.string()
            .nullable()
            .when([formFieldNames.OnlineStore], {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required(
                        i18n.t('Forms.Validation.FieldRequired', {
                            field: i18n.t('Forms.Labels.Selection'),
                        })
                    ),
            }),
        [formFieldNames.PhysicalStore]: Yup.bool().nullable(),
        [formFieldNames.ServiceAddress]: Yup.string()
            .trim()
            .nullable()
            .when([formFieldNames.LocationAddressType], {
                is: formFieldNames.ServiceAddress,
                then: Yup.string()
                    .nullable()
                    .required(
                        i18n.t('Forms.Validation.FieldRequired', {
                            field: i18n.t('Forms.ServiceArea.ServiceAddressLabel'),
                        })
                    ),
            }),
        [formFieldNames.Travels]: Yup.bool().nullable(),
        [formFieldNames.TravelOption]: Yup.string()
            .nullable()
            .required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.Selection'),
                })
            ),
        [formFieldNames.TravelDistance]: Yup.string()
            .nullable()
            .when(formFieldNames.TravelOption, {
                is: travelOptions.Locally,
                then: Yup.string()
                    .nullable()
                    .required(
                        i18n.t('Forms.Validation.FieldRequired', {
                            field: i18n.t('Forms.Labels.Selection'),
                        })
                    ),
            }),
        [formFieldNames.ServiceArea]: Yup.string()
            .trim()
            .nullable()
            .when(formFieldNames.TravelDistance, {
                is: (val) => val?.length > 0,
                then: Yup.string()
                    .nullable()
                    .required(
                        i18n.t('Forms.Validation.FieldRequired', {
                            field: i18n.t('Forms.Labels.Selection'),
                        })
                    ),
            }),
    })
    .required();
export { serviceAreaFormSchema };
