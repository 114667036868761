import styled from 'styled-components';

const EditPartnersPageStyled = styled.form`
    display: flex;
    flex-flow: column;

    .error {
        color: ${(props) => props.theme.colors.error.base};
        margin-bottom: 16px;
    }

    h2 {
        text-transform: none;
    }
`;

export { EditPartnersPageStyled };
