/* eslint-disable no-console, no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    COMPLETE_STEP,
    NEW_DEFAULT_STEP,
    WebstoreSetupStepsMap,
    NewWebstoreSetupStepForms,
} from 'vendor/constants/WebstoreSetupSteps';
import { WebstoreService } from 'vendor/lib/apis/WebstoreService';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useVendorContext } from 'vendor/contexts/VendorContext';
import { APP_ROUTES } from 'shared/constants/navigation';
import { unwrapResponse } from '../../../lib/apis/utils';
import { formFieldNames } from 'framework/lib/WebstoreAddressService/WebstoreAddressService';
import { WebstoreAddressService } from 'framework';

const WebstoreSetupContext = React.createContext();
WebstoreSetupContext.displayName = 'WebstoreSetupContext';

const setupRoutePrefix = APP_ROUTES.vendor.webstore.newsetup + '/';
const getSetupStepRoute = (step) => `${setupRoutePrefix}${step}`;

const useWebstoreSetupWizard = () => {
    const { vendor } = useVendorContext();
    const [webstoreSetupProps] = React.useContext(WebstoreSetupContext);
    const { webstore, progress, totalSteps, currentStepCount, stepsArray, patchFunction } =
        webstoreSetupProps;
    const location = useLocation();
    const navigate = useNavigate();
    const { getBusinessAddress, createNewBusinessAddress, copyBusinessAddressToServiceLocation } =
        WebstoreAddressService;

    const getStep = (indexOffset) => {
        const currentIndex = stepsArray.indexOf(webstore.CurrentSetupStep);
        return stepsArray[currentIndex + indexOffset];
    };

    const onBack = () => {
        const previousStep = getStep(-1);
        navigate(getSetupStepRoute(previousStep));
    };

    const onSkip = () => {
        const nextStep = getStep(1);
        navigate(getSetupStepRoute(nextStep));
    };

    const returnToBusinessLocations = async () => {};

    const onReturnToBusinessLocations = vendor.webstoreCount > 1 ? returnToBusinessLocations : null;
    const handleAddress = async (formData) => {
        const updatedAddress = {
            address1: formData[formFieldNames.ServiceAddressStreet],
            city: formData[formFieldNames.ServiceAddressCity],
            state: formData[formFieldNames.ServiceAddressState],
            zipCode: formData[formFieldNames.ServiceAddressZipcode],
            addressType: 'business',
        };

        let businessAddress = getBusinessAddress(webstore);

        if (businessAddress) {
            Object.keys(updatedAddress).forEach((key) => {
                businessAddress[key] = updatedAddress[key];
            });
        } else {
            businessAddress = {
                ...createNewBusinessAddress(),
                ...updatedAddress,
            };
            webstore.Addresses.push(businessAddress);
        }

        if (!!webstore.ServiceAddressIsBusinessAddress) {
            copyBusinessAddressToServiceLocation(webstore);
        }
    };
    const onSubmit = async (formData) => {
        const currentPage = location.pathname.slice(
            location.pathname.indexOf(setupRoutePrefix) + setupRoutePrefix.length
        );

        try {
            if (formData) {
                if (!webstore.CurrentSetupStep && currentPage === NewWebstoreSetupStepForms.PLAN) {
                    webstore.CurrentSetupStep = NEW_DEFAULT_STEP;
                    await webstoreSetupProps.updateStep();
                    await webstore.updateWebstore(formData);
                    const patch = await patchFunction(webstore.forApi);
                    if (patch.ok) {
                        webstore.setFromApi(patch.data);
                    } else {
                        console.error(patch.originalError);
                    }
                } else {
                    await webstore.updateWebstore(formData);
                    if (currentPage === NewWebstoreSetupStepForms.BUSINESS) {
                        await handleAddress(formData);
                    }
                    const patch = await patchFunction(webstore.forApi);
                    if (patch.ok) {
                        webstore.setFromApi(patch.data);
                    } else {
                        console.error(patch.originalError);
                    }
                }
            }
            const nextStep = getStep(1);
            if (nextStep) {
                navigate(getSetupStepRoute(nextStep));
            } else {
                webstore.CurrentSetupStep = COMPLETE_STEP;
                await webstoreSetupProps.updateStep();
                navigate(APP_ROUTES.vendor.webstore.root);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return {
        onSubmit,
        onBack,
        onSkip,
        onReturnToBusinessLocations,
        progress,
        totalSteps,
        currentStepCount,
    };
};

const NewWebstoreSetupWizardProvider = ({ children }) => {
    const webstoreApi = WebstoreService();
    const { webstore } = useWebstoreContext();
    const { vendor } = useVendorContext();
    const location = useLocation();
    const navigate = useNavigate();

    const getMaxStepCount = () => {
        let stepCount = Object.keys(NewWebstoreSetupStepForms).length;
        return stepCount;
    };

    const recalcSteps = (options) => {
        const { locationChanged } = options;
        const updateStep = webstoreSetup.updateStep;

        if (locationChanged) {
            const currentPage = location.pathname.slice(
                location.pathname.indexOf(setupRoutePrefix) + setupRoutePrefix.length
            );

            const validPages = Object.values(NewWebstoreSetupStepForms);
            if (!!vendor.id) {
                if (validPages.includes(currentPage)) {
                    if (webstore.CurrentSetupStep !== currentPage) {
                        webstore.CurrentSetupStep = currentPage;
                        updateStep();
                    }
                } else {
                    if (!validPages.includes(webstore.CurrentSetupStep)) {
                        webstore.CurrentSetupStep = NEW_DEFAULT_STEP;
                        updateStep();
                    }
                    navigate(getSetupStepRoute(webstore.CurrentSetupStep));
                }
            }
        }

        let stepsArray = webstoreSetup.stepsArray;

        const currentStepCount = stepsArray.indexOf(webstore.CurrentSetupStep) + 1;

        const totalSteps = stepsArray.length > 1 ? stepsArray.length : getMaxStepCount();

        const progress = currentStepCount / (totalSteps - 1);

        setWebstoreSetup({
            ...webstoreSetup,
            progress: progress,
            totalSteps: totalSteps,
            currentStepCount: currentStepCount,
            stepsArray: stepsArray,
        });
    };

    const updateStep = async () => {
        if (!!vendor.id && !!webstore.Id) {
            const response = await webstoreApi.patchWebstore(webstore.forPatchSetupStep);
            const patchedStore = unwrapResponse(response);
            webstore.setFromApi(patchedStore);
        }
    };

    const defaultWebstoreSetupProps = {
        patchFunction: webstoreApi.patchWebstore,
        stepsArray: [...Object.values(NewWebstoreSetupStepForms)],
        progress: 0,
        totalSteps: getMaxStepCount(),
        currentStepCount: 1,
        webstore,
        updateStep,
    };

    const [webstoreSetup, setWebstoreSetup] = useState(defaultWebstoreSetupProps);

    useEffect(() => {
        recalcSteps({ locationChanged: true });
    }, [location]);

    return (
        <WebstoreSetupContext.Provider value={[webstoreSetup, setWebstoreSetup]}>
            {children}
        </WebstoreSetupContext.Provider>
    );
};

export { useWebstoreSetupWizard, NewWebstoreSetupWizardProvider };
