import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ToggleSwitch, TextInput } from 'dbi-pearl-ui-kit';
import {
    FormRow,
    PearlError,
    loadAutocomplete,
    addressFieldToGoogleResponseMapping,
} from 'framework';
import { formFieldNames } from '../Functions/ServiceAreaForm.Functions';
import { PhysicalLocationOptionsStyled } from './PhysicalLocationOptions.styled';

const updateMap = (mapFunctions, svcLocationQueryResult) => {
    if (mapFunctions) {
        const { setMapCenter, setMapZoom, setMapAlt } = mapFunctions;

        setMapCenter(svcLocationQueryResult.geometry.location.toJSON());
        setMapZoom(15);
        setMapAlt(svcLocationQueryResult.formatted_address);
    }
};

const hiddenFieldNames = {
    street1: 'ServiceAddressStreet',
    city: 'ServiceAddressCity',
    state: 'ServiceAddressState',
    zipcode: 'ServiceAddressZipcode',
};

const PhysicalLocationOptions = (props) => {
    const { physicalStoreOptionsClickHandler, mapFunctions, webstore } = props;

    const { setValue, watch, getValues } = useFormContext();
    const { t } = useTranslation();

    const [queryRef, setQueryRef] = useState(null);
    const [svcLocationQueryResult, setSvcLocationQueryResult] = useState('');

    const addressTypeField = watch(formFieldNames.LocationAddressType);
    const serviceAddressField = watch(formFieldNames.ServiceAddress);

    const [displayAddressInput, setDisplayAddressInput] = useState(
        addressTypeField === formFieldNames.ServiceAddress
    );

    const handleAddressTypeChange = (isDifferentAddress) => {
        const newAddressType = isDifferentAddress
            ? formFieldNames.ServiceAddress
            : formFieldNames.BusinessAddress;

        physicalStoreOptionsClickHandler(setValue, webstore, newAddressType, getValues);
    };

    useEffect(() => {
        if (addressTypeField === formFieldNames.ServiceAddress) {
            setDisplayAddressInput(true);
        } else {
            setDisplayAddressInput(false);
        }
    }, [addressTypeField]);

    useEffect(() => {
        if (queryRef) {
            const autocompleteOptions = {
                searchTypes: ['address'],
                setFields: ['address_components', 'formatted_address', 'geometry'],
                focusRef: 'label[role="checkbox"]',
                scriptId: 'physical-location-options-autocomplete',
            };
            loadAutocomplete(setSvcLocationQueryResult, queryRef, autocompleteOptions);

            if (!svcLocationQueryResult && serviceAddressField?.length > 0) {
                setValue(formFieldNames.ServiceAddress, serviceAddressField);
                setValue(
                    formFieldNames.ServiceAddressStreet,
                    serviceAddressField.split(',')?.[0]?.trim()
                );
                setValue(
                    formFieldNames.ServiceAddressCity,
                    serviceAddressField.split(',')?.[1]?.trim()
                );
                setValue(
                    formFieldNames.ServiceAddressState,
                    serviceAddressField.split(',')?.[2]?.trim()
                );
                setValue(
                    formFieldNames.ServiceAddressZipcode,
                    serviceAddressField.split(',')?.[3]?.trim()
                );
            }
        } else {
            const ref = document.querySelector('#service-address');
            setQueryRef(ref);
        }
    }, [queryRef, serviceAddressField]);

    useEffect(() => {
        if (svcLocationQueryResult && svcLocationQueryResult.address_components) {
            addressFieldToGoogleResponseMapping(setValue, svcLocationQueryResult, hiddenFieldNames);

            if (mapFunctions) {
                updateMap(mapFunctions, svcLocationQueryResult);
            }
        }
    }, [svcLocationQueryResult]);

    return (
        <PhysicalLocationOptionsStyled className={`display-`}>
            <p className="option-question" style={{ marginTop: '4px' }}>
                <b>{t('Forms.ServiceArea.ServiceAddress')}</b>
            </p>
            <div className="address-type">
                <div className="toggle-container">
                    <ToggleSwitch
                        checked={addressTypeField === formFieldNames.ServiceAddress}
                        onChange={(isChecked) => handleAddressTypeChange(isChecked)}
                        name={formFieldNames.LocationAddressType}
                    />
                    <p>
                        {addressTypeField === formFieldNames.ServiceAddress
                            ? t('Forms.ServiceArea.ServiceAddressDifferent')
                            : t('Forms.ServiceArea.ServiceAddressSame')}
                    </p>
                </div>
                <PearlError name={formFieldNames.LocationAddressType} />
            </div>
            {!!displayAddressInput && (
                <div className="serviceContainer">
                    <FormRow
                        className="serviceRow"
                        columns={[
                            <div key={0} className="serviceAddressOuter">
                                <TextInput
                                    formFieldName={formFieldNames.ServiceAddress}
                                    name={formFieldNames.ServiceAddress}
                                    id="service-address"
                                    data-testid="service-address"
                                    autoComplete="off"
                                />
                                <PearlError name={formFieldNames.ServiceAddress} />
                            </div>,
                        ]}
                    />
                    <div className=" displayToggleService">
                        <ToggleSwitch
                            checked={getValues(formFieldNames.DisplayStoreAddress)}
                            onChange={(e) => {
                                setValue(formFieldNames.DisplayStoreAddress, e);
                            }}
                            name={formFieldNames.DisplayStoreAddress}
                        />
                        <div>
                            <p className="toggle-subtitle2 ">
                                {t('Forms.ServiceArea.DisplayToggleSubtitle')}
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {!displayAddressInput && (
                <FormRow
                    className="displayRow"
                    columns={[
                        <div
                            key={0}
                            className="checkboxGroup displayToggleService"
                            style={{ marginTop: '0px' }}
                        >
                            <ToggleSwitch
                                checked={getValues(formFieldNames.DisplayStoreAddress)}
                                onChange={(e) => {
                                    setValue(formFieldNames.DisplayStoreAddress, e);
                                }}
                                name={formFieldNames.DisplayStoreAddress}
                            />
                            <div>
                                <p className="toggle-subtitle2">
                                    {t('Forms.ServiceArea.DisplayToggleSubtitleTwo')}
                                </p>
                            </div>
                        </div>,
                    ]}
                />
            )}
        </PhysicalLocationOptionsStyled>
    );
};

export { PhysicalLocationOptions };
