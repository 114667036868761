import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const WelcomeScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    background-color: #fff;
    height: 280px;
    .checkmark {
        color: green;
    }
    .messageBox {
        text-align: center;
    }
`;

const WelcomeTitle = styled.h4`
    margin-bottom: 24px;
    text-transform: none;
    color: #132122;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
`;

const WelcomeSubtitle = styled.h4`
    text-transform: none;
`;

const Container = styled.div`
    max-width: 826px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 16px;
    position: relative;
`;

const RotatedImage = styled.img`
    transform: rotate(2.218deg);
    align-self: flex-start;
    position: relative;
    top: 13px;
    right: 10px;
    width: auto;
    height: auto;
    ${(props) => props.theme.pearlBreaks?.down(BreakpointSizes.MD)} {
        width: auto;
        height: 120px;
    }
    ${(props) => props.theme.pearlBreaks?.down(BreakpointSizes.SM)} {
        width: auto;
        height: 100px;
    }
`;

const NormalImage = styled.img`
    width: auto;
    height: auto;
    ${(props) => props.theme.pearlBreaks?.down(BreakpointSizes.MD)} {
        width: 500px;
        height: auto;
    }
    ${(props) => props.theme.pearlBreaks?.down(BreakpointSizes.SM)} {
        width: 330px;
        height: auto;
    }
`;

export {
    WelcomeScreenContainer,
    WelcomeTitle,
    WelcomeSubtitle,
    Container,
    RotatedImage,
    NormalImage,
};
