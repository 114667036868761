import styled from 'styled-components';

export default styled.div`
    height: auto;
    width: 100%;

    .boxes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 17px;
        grid-auto-rows: 1fr;
        min-height: fit-content;
        @media (max-width: 980px) {
            grid-template-columns: 1fr;
        }
    }

    .price-tier-wrapper {
        margin-top: 20px;
        height: 100%;
    }
`;
