import React, { useEffect } from 'react';
import { ListView } from 'dbi-pearl-ui-kit';
import styled from 'styled-components';
import { FormRow } from 'framework';
import { PearlError } from 'framework';
import { SectionEditHeader } from './Webstore/SectionEdit/SectionEditHeader/SectionEditHeader';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

const VendorSubcategorySection = styled.div`
    .miniSubCatList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .list-items {
                display: grid;
                width: 100%;
                grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
                gap: 16px;

                & > div {
                    height: 49px;
                    padding: 0px 16px;
                    border-radius: 8px;
                    border-width: 2px;
                    box-shadow: 0px 2px 6px 0px rgba(19, 33, 34, 0.08);

                    & > label {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                    & > label > div {
                        display: flex;
                        flex-direction: column;
                    }

                    &:not(.is-checked) {
                        border-color: #e0e2e2;
                    }

                    .checkbox-label {
                        position: relative;
                        top: 0px;
                        text-transform: capitalize;
                        font-family: 'Objektiv Mk2 Bold';
                        font-size: 14px;
                        letter-spacing: 0px;
                        margin-left: 0px;
                    }
                }
            }
            .check {
                display: none;
            }
        }
    }
`;

const VendorSubcategoryMultiSelect = (props) => {
    const { selectedCategoryDetail } = props;
    const { t } = useTranslation();
    const { watch, clearErrors } = useFormContext();
    const subCategory = watch('VendorSubCategoryIds');

    useEffect(() => {
        if (subCategory) {
            Object.keys(subCategory).forEach((key) => {
                if (subCategory[key].length > 0) {
                    clearErrors(`VendorSubCategoryIds.${key}`);
                }
            });
        }
    }, [subCategory]);

    return (
        <VendorSubcategorySection>
            {selectedCategoryDetail &&
                selectedCategoryDetail.map((item) => {
                    return (
                        <FormRow
                            key={item.id}
                            columns={[
                                <div key={item.id} style={{ position: 'relative' }}>
                                    <SectionEditHeader
                                        title={t('Forms.Labels.SelectSubCategories', {
                                            subcat: item.label,
                                        })}
                                        description={t('Forms.EditBasics.SubCatDescription')}
                                    />
                                    <div className="miniSubCatList">
                                        <ListView
                                            formFieldName="VendorSubCategoryIds"
                                            multiSelectSubcategory={true}
                                            listItems={item?.subCategories}
                                        ></ListView>
                                        <PearlError
                                            name={`VendorSubCategoryIds.${item.id}`}
                                        ></PearlError>
                                    </div>
                                </div>,
                            ]}
                        ></FormRow>
                    );
                })}
        </VendorSubcategorySection>
    );
};

export { VendorSubcategoryMultiSelect };
