import React from 'react';
import { WelcomeScreenContainer, Container, RotatedImage, NormalImage } from './SuccessPage.Styled';
import WelcomeImage from 'assets/welcome.png';
import PearlByDavids from 'assets/pearl-by-davids.png';

const SuccessPage = ({ children }) => {
    return (
        <WelcomeScreenContainer className="welcomeBannerContainer" data-testid="welcome-container">
            <Container>
                <RotatedImage src={WelcomeImage} alt="WelcomeImage" data-testid="welcome-image" />
                <NormalImage
                    src={PearlByDavids}
                    alt="PearlByDavids"
                    data-testid="pearl-by-davids"
                />
            </Container>
            <div className="messageBox">{children}</div>
        </WelcomeScreenContainer>
    );
};

export default SuccessPage;
