import styled from 'styled-components';

const SectionEditHeaderStyled = styled.div`
    width: 100%;

    .title {
        color: ${(props) => props.theme.colors.foreground.text};
        & > b {
            font-size: 16px;
        }
    }

    .subtitle {
        margin-block: 0;
        margin-top: 8px;
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 21px; /* 150% */
    }

    .description {
        a {
            color: ${(props) => props.theme.colors.primary.base};
        }
    }
`;

export { SectionEditHeaderStyled };
