import styled from 'styled-components';

export default styled.div`
    flex: 1 1 0;
    padding-bottom: 24px;

    &:hover {
        .box {
            background-color: ${(props) => props.theme.colors.background.base};
        }

        input:checked + label .box .boxLeftContent {
            .dollarsSymbol {
                color: ${(props) => props.theme.colors.primary.contrast};
            }
        }

        .boxRightContent {
            color: ${(props) => props.theme.colors.primary.contrast};
        }
    }

    input {
        position: fixed;
        opacity: 0;
        pointer-events: auto;
    }

    .box {
        border: 2px solid ${(props) => props.theme.colors.foreground.border};
        display: flex;
        cursor: pointer;
        min-height: 100%;
        border-radius: 8px;
        box-shadow: 0px 2px 6px 0px rgba(19, 33, 34, 0.08);
    }
    .disabled {
        cursor: default;
        background-color: ${(props) => props.theme.colors.background.base};
    }
    .boxLeftContent {
        display: flex;
        min-width: 44px;
        min-height: 44px;
        margin: auto 16px;
        justify-content: center;
        align-items: center;
        padding: 0px;
    }
    .boxMiddleContent {
        margin: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;

        .p2 {
            margin-block: 0;
            padding-top: 6px;
            color: #424d4e;
            line-height: 21px; /* 150% */
        }
    }
    input:checked + label .box {
        border: 2px solid ${(props) => props.theme.colors.primary.base};
    }
    input:checked + label .box .boxLeftContent {
        color: ${(props) => props.theme.colors.primary.base};
    }
    input:checked + label .box .dollarsSymbol {
        color: ${(props) => props.theme.colors.primary.base};
    }

    .boxTitle {
        font-family: 'Objektiv Mk2';
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important ;
        line-height: 16.8px; /* 120% */
        text-transform: capitalize;
    }
`;
