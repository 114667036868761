import React, { useState, useEffect } from 'react';
import PickYourPlanSectionStyled from './PickYourPlanSection.styled';
import { useTranslation } from 'react-i18next';
import { PickYourPlanTile, PearlError } from 'framework';
import { useFormContext } from 'react-hook-form';
import { formatPrice, sortByPrice } from 'framework/constants/WebstorePlanTiers';
import { StripeService } from 'lib/apis/StripeServiceNew';
import { StorageService } from 'lib/StorageService';

const PickYourPlanSection = ({ defaultPlanValue }) => {
    const formFieldName = 'SelectedPlan';
    const { getValues } = useFormContext();
    const SelectedPlan = getValues('SelectedPlan');

    const { t } = useTranslation();

    const [selectedPlan, setSelectedPlan] = useState(defaultPlanValue);
    const [expandedPlan, setExpandedPlan] = useState(null);
    const [planList, setPlanList] = useState([]);
    const storageService = StorageService();

    useEffect(() => {
        const getStripeData = async () => {
            try {
                const stripeService = StripeService();
                const response = await stripeService.getStripeProducts();
                const result = await response?.data;
                storageService.set('stripeData', JSON.stringify(result));
                if (result) {
                    const parsedStripeData = result;
                    const Plans = parsedStripeData
                        .map((plan) => {
                            if (plan.billingFrequency === 'month' && plan.price > 0) {
                                return plan?.planTier;
                            }
                            return null;
                        })
                        .filter((item) => item !== null);

                    const fetchedPlanList = Plans.map((plan) => {
                        const matchingItem = parsedStripeData.find(
                            (data) => data.planTier === plan && data.billingFrequency === 'month'
                        );
                        return matchingItem
                            ? {
                                  id: matchingItem.id,
                                  value: formatPrice(matchingItem.price),
                                  title: matchingItem.tierName,
                                  period: 'Forms.PlanTier.Period',
                                  altPrice: `Forms.PlanTier.AltPrice.${plan}`,
                                  altPriceLink: `Forms.PlanTier.AltPriceLink.${plan}`,
                                  description: `Forms.PlanTier.Description.${plan}`,
                                  benefits: `Forms.PlanTier.Benefits.${plan}`,
                                  isPopular: `Forms.PlanTier.Popular.${plan}`,
                                  planInfoBtn: `Forms.PlanTier.PlanInfoButton.${plan}`,
                                  planInfo: `Forms.PlanTier.PlanInfo.${plan}`,
                                  tierName: matchingItem.planTier,
                              }
                            : null;
                    }).filter((item) => item !== null);

                    setPlanList(sortByPrice(fetchedPlanList));
                }
            } catch (error) {
                console.error("Couldn't receive stripe data from DB");
            }
        };
        getStripeData();
    }, []);

    const handleToggleClick = (id) => {
        setExpandedPlan(expandedPlan === id ? null : id);
    };

    return (
        <PickYourPlanSectionStyled>
            <label className="subtitleOne">{t('Forms.PlanTier.SubtitleOne')}</label>
            {!SelectedPlan && <PearlError name="SelectedPlan"></PearlError>}
            <div className="price-tier-wrapper">
                <div className="boxes">
                    {planList?.map((tier) => {
                        const benefits = t(tier.benefits, {
                            returnObjects: true,
                        });
                        return (
                            <PickYourPlanTile
                                title={t(tier.title)}
                                price={`$${t(tier.value)}${t(tier.period)}`}
                                period={t(tier.period)}
                                value={t(tier.value)}
                                altPrice={t(tier.altPrice)}
                                altPriceLink={t(tier.altPriceLink)}
                                description={t(tier.description)}
                                benefits={benefits}
                                isPopular={t(tier.isPopular)}
                                key={t(tier.id)}
                                planId={t(tier.id)}
                                planInfo={t(tier.planInfo)}
                                formFieldName={formFieldName}
                                onClick={() => setSelectedPlan(tier.tierName)}
                                isSelected={selectedPlan === tier.tierName}
                                isOpen={expandedPlan === tier.id}
                                onToggleClick={() => handleToggleClick(tier.id)}
                                planInfoBtn={t(tier.planInfoBtn)}
                                planTier={tier.tierName}
                            />
                        );
                    })}
                </div>
                {planList?.length > 0 && (
                    <div className="footerText">{t('Forms.PlanTier.FooterText')}</div>
                )}
            </div>
        </PickYourPlanSectionStyled>
    );
};

export default PickYourPlanSection;
