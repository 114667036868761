import * as Yup from 'yup';
import i18n from 'i18n';
import { usePromoCode } from '../Checkout/PromoCodeContext';

const CheckoutFormSchemaWrapper = () => {
    const { promoCodeDiscount } = usePromoCode();
    const CheckoutFormSchema = Yup.object()
        .shape({
            CardholderName: Yup.string().required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.StripeCheckout.CardholderNameLabel'),
                })
            ),
            PromoCode: Yup.string().when([], {
                is: () => promoCodeDiscount && promoCodeDiscount?.length > 0,
                then: Yup.string().required(
                    i18n.t('Forms.Validation.FieldRequired', {
                        field: i18n.t('Forms.StripeCheckout.PromoCodeLabel'),
                    })
                ),
                otherwise: Yup.string(),
            }),
            CountryRegion: Yup.string().required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.StripeCheckout.CountryRegion'),
                })
            ),
        })
        .required();
    return CheckoutFormSchema;
};

export { CheckoutFormSchemaWrapper };
