import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ServiceAreaFormStyled } from './ServiceArea.styled';
import { ServiceTypeOptions } from './ServiceTypeOptions/ServiceTypeOptions';
import { OnlineOptions } from './OnlineOptions/OnlineOptions';
import { PhysicalLocationOptions } from './PhysicalLocationOptions/PhysicalLocationOptions';
import {
    formFieldNames,
    onlineStoreClickHandler,
    physicalStoreClickHandler,
    physicalStoreOptionsClickHandler,
    travelsClickHandler,
    travelOptionsClickHandler,
    localTravelClickHandler,
    setServiceCityState,
} from './Functions/ServiceAreaForm.Functions';
import { TravelOptions } from './TravelOptions/TravelOptions';
import { WebstoreAddressService } from 'framework';
import { TextInput } from 'dbi-pearl-ui-kit';
import {
    FormRow,
    PearlError,
    loadAutocomplete,
    addressFieldToGoogleResponseMapping,
} from 'framework';
import { GeocodeService } from 'lib/apis/GeocodeService';
import { SectionEditHeader } from '../SectionEdit/SectionEditHeader/SectionEditHeader';

//This is used in the webstore setup flow & shown in the webstore edit pgs.
//Online isn't an option for the setup flow, but could be an option on webstore edit pgs based off subscription feature
function ServiceAreaForm(props) {
    const { webstore, mapFunctions, showOnlineOptions } = props;
    const { ensureTravelOptionIfDistanceProvided, travelOptions } = WebstoreAddressService;

    const { setValue, watch, clearErrors } = useFormContext();

    //Online State Management
    const [canShowOnline] = useState(!!showOnlineOptions && !!webstore.OnlineAllowed);
    const [onlineStore, setOnlineStore] = useState(canShowOnline && !!webstore.OnlineStore);

    //Physical Store State Management
    const [physicalStore, setPhysicalStore] = useState(!!webstore.PhysicalStore);

    //Travels State Management
    const [travel, setTravel] = useState(!!webstore.Travels);
    const [localTravel, setLocalTravel] = useState(webstore.TravelOption === travelOptions.Locally);
    const [travelOptionSelected, setTravelOptionSelected] = useState(
        ensureTravelOptionIfDistanceProvided(webstore)
    );

    //Business Address
    const [queryRef, setQueryRef] = useState(null);
    const [svcLocationQueryResult, setSvcLocationQueryResult] = useState('');
    const geoCodeService = GeocodeService();
    const [latLng, setLatLng] = useState({});
    const [placeId, setPlaceId] = useState('');

    const businessAddressFieldName = 'BusinessAddress';
    const textBoxRef = '#business-address-autocomplete';
    const focusOnLocationSelection = '#business-address-wrapper';

    const hiddenFieldNames = {
        street1: formFieldNames.BusinessAddressStreet1,
        street2: formFieldNames.BusinessAddressStreet2,
        city: formFieldNames.BusinessAddressCity,
        state: formFieldNames.BusinessAddressState,
        zipcode: formFieldNames.BusinessAddressZipcode,
    };

    const businessAddressField = watch(businessAddressFieldName);

    useEffect(() => {
        if (svcLocationQueryResult && svcLocationQueryResult.address_components) {
            addressFieldToGoogleResponseMapping(setValue, svcLocationQueryResult, hiddenFieldNames);
        }
        if (businessAddressField && businessAddressField.trim() !== '') {
            clearErrors(businessAddressFieldName);
        }
    }, [svcLocationQueryResult, businessAddressFieldName]);

    useEffect(() => {
        if (queryRef) {
            const autocompleteOptions = {
                searchTypes: ['address'],
                setFields: ['address_components', 'formatted_address', 'geometry'],
                focusRef: focusOnLocationSelection,
                scriptId: 'business-address-autocomplete-script',
            };

            loadAutocomplete(setSvcLocationQueryResult, queryRef, autocompleteOptions);

            if (!svcLocationQueryResult && businessAddressField?.length > 0) {
                const commaCount = businessAddressField.match(/,/g)?.length;

                setValue('BusinessAddress', businessAddressField);
                setValue(hiddenFieldNames.street1, businessAddressField.split(',')?.[0]?.trim());

                if (commaCount < 4) {
                    setValue(hiddenFieldNames.city, businessAddressField.split(',')?.[1]?.trim());
                    setValue(hiddenFieldNames.state, businessAddressField.split(',')?.[2]?.trim());
                    setValue(
                        hiddenFieldNames.zipcode,
                        businessAddressField.split(',')?.[3]?.trim()
                    );
                } else {
                    setValue(
                        hiddenFieldNames.street2,
                        businessAddressField.split(',')?.[1]?.trim()
                    );
                    setValue(hiddenFieldNames.city, businessAddressField.split(',')?.[2]?.trim());
                    setValue(hiddenFieldNames.state, businessAddressField.split(',')?.[3]?.trim());
                    setValue(
                        hiddenFieldNames.zipcode,
                        businessAddressField.split(',')?.[4]?.trim()
                    );
                }
            }
        } else {
            const ref = document.querySelector(textBoxRef);
            setQueryRef(ref);
        }
    }, [queryRef, businessAddressField]);

    const city = watch(formFieldNames.BusinessAddressCity); // watch BusinessAddressCity
    const state = watch(formFieldNames.BusinessAddressState);

    useEffect(() => {
        let isMounted = true;
        if (city && state) {
            const fetchGeocode = async () => {
                const res = await geoCodeService.getGeocodeByAddress(city, state);
                if (isMounted) {
                    const lat = res.latitude;
                    const lng = res.longitude;
                    const place_id = res.place_id;
                    setLatLng({ lat, lng });
                    setPlaceId(place_id);
                }
            };
            fetchGeocode();
        }
        return () => {
            isMounted = false;
        };
    }, [city, state]);

    return (
        <ServiceAreaFormStyled showBottomBorder={false}>
            <div>
                <FormRow
                    columns={[
                        <div
                            key={1}
                            data-testid="business-address-wrapper"
                            id="business-address-wrapper"
                        >
                            <TextInput
                                formFieldName={businessAddressFieldName}
                                name={businessAddressFieldName}
                                id="business-address-autocomplete"
                                data-testid="business-address-autocomplete"
                                providedLabel="Forms.EditBasics.BusinessLocationLabel"
                            />
                            <PearlError name={'BusinessAddress'} />
                        </div>,
                    ]}
                />
            </div>
            <ServiceTypeOptions
                onlineOptionAllowed={canShowOnline}
                travelOptionAllowed={true}
                onlineStore={onlineStore}
                onlineStoreClickHandler={() =>
                    onlineStoreClickHandler(
                        setValue,
                        onlineStore,
                        setOnlineStore,
                        travel,
                        physicalStore
                    )
                }
                physicalStore={physicalStore}
                physicalStoreClickHandler={() =>
                    physicalStoreClickHandler(
                        setValue,
                        physicalStore,
                        setPhysicalStore,
                        travel,
                        canShowOnline,
                        onlineStore
                    )
                }
                travel={travel}
                travelsClickHandler={() =>
                    travelsClickHandler(
                        setValue,
                        travel,
                        setTravel,
                        physicalStore,
                        setTravelOptionSelected,
                        canShowOnline,
                        onlineStore
                    )
                }
            />
            {!!onlineStore && <OnlineOptions webstore={webstore} mapFunctions={mapFunctions} />}
            {!!physicalStore && (
                <PhysicalLocationOptions
                    physicalStoreOptionsClickHandler={physicalStoreOptionsClickHandler}
                    mapFunctions={mapFunctions}
                    webstore={webstore}
                />
            )}

            <>
                <div className="serviceAreaTitle">
                    <SectionEditHeader
                        title="Forms.ServiceArea.EditSectionTitle"
                        description="Forms.ServiceArea.EditSectionDescription"
                    ></SectionEditHeader>
                </div>
                <TravelOptions
                    mapFunctions={mapFunctions}
                    travelOptionsClickHandler={(optionSelected) =>
                        travelOptionsClickHandler(
                            setValue,
                            optionSelected,
                            setTravelOptionSelected,
                            localTravelClickHandler,
                            setLocalTravel
                        )
                    }
                    travelOptionSelected={travelOptionSelected}
                    localTravel={localTravel}
                    physicalStore={physicalStore}
                    setServiceCityState={setServiceCityState}
                    addressLatLng={latLng}
                    placeId={placeId}
                />
            </>
        </ServiceAreaFormStyled>
    );
}

export { ServiceAreaForm, formFieldNames };
