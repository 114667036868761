import styled from 'styled-components';
import { CapacitySectionStyled } from 'vendor/components/Webstore/CapacitySection/CapacitySection.styled';

const CapacitySectionEditStyled = styled.section`
    ${CapacitySectionStyled} {
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 17px;
        grid-auto-rows: 1fr;
    }
    .Capacity-container {
        border-radius: 8px;
    }

    @media (max-width: 1160px) {
        ${CapacitySectionStyled} {
            grid-template-columns: 1fr;
        }
    }
`;

export { CapacitySectionEditStyled };
