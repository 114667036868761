import styled from 'styled-components';

const SocialLinksSectionEditStyled = styled.section`
    .link-row {
        & > div > div > div > div {
            border-radius: 8px;
        }
    }
`;

export { SocialLinksSectionEditStyled };
