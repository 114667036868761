import * as Yup from 'yup';
import i18n from 'i18n';

const vendorBusinessDetailsFormSchemaWrapper = (categoryList, isBusinessName) => {
    const vendorBusinessDetailsFormSchema = Yup.object()
        .shape({
            WebstoreName: Yup.string()
                .max(
                    120,
                    i18n.t('Forms.Validation.CannotExceedCharacterLimit', {
                        characterLimit: '120',
                    })
                )
                .nullable()
                .required(
                    i18n.t('Forms.Validation.FieldRequired', {
                        field: isBusinessName
                            ? i18n.t('Forms.EditBasics.BusinessNameLabel')
                            : i18n.t('Forms.Labels.WebstoreName'),
                    })
                ),
            VendorCategoryId: Yup.string()
                .label(i18n.t('Forms.Labels.VendorCategory'))
                .nullable()
                .min(1, i18n.t('Forms.Validation.VendorCategory'))
                .required(
                    i18n.t('Forms.Validation.FieldRequired', {
                        field: i18n.t('Forms.Labels.VendorCategory'),
                    })
                ),
            VendorSubCategoryIds: Yup.object()
                .shape({
                    dynamicKeys: Yup.lazy((value) =>
                        Yup.object().shape(
                            Object.keys(value || {}).reduce((acc, key) => {
                                if (/^\d+$/.test(key)) {
                                    acc[key] = Yup.array().of(Yup.number()).nullable();
                                }
                                return acc;
                            }, {})
                        )
                    ),
                })
                .label(i18n.t('Forms.Labels.VendorSubCategory'))
                .nullable()
                .test(
                    'checkSubCategoriesBelongToCategory',
                    'vendor subcategory field must have at least 1 option selected',
                    async function (value, options) {
                        const { createError, path, parent } = this; // Destructure useful variables from `this`
                        const selectedWebstoreId = parent.VendorCategoryId;

                        if (selectedWebstoreId && value) {
                            const category = categoryList.filter((x) =>
                                selectedWebstoreId
                                    .split(',')
                                    .map((item) => parseFloat(item.trim()))
                                    .includes(x.id)
                            );

                            for (let item of category) {
                                if (item) {
                                    const validSubcategoryIds = item.subCategories.map((x) => x.id);

                                    // Check if the subcategory array is empty for this category
                                    if (value[item.id] && value[item.id].length === 0) {
                                        return createError({
                                            path: `${path}.${item.id}`, // Dynamic error path
                                            message:
                                                'vendor subcategory field must have at least 1 option selected',
                                        });
                                    }

                                    // Check if any of the selected subcategories are invalid
                                    const selectedSubcategories = value[item.id];
                                    const invalidSubcategories = selectedSubcategories?.some(
                                        (subCategoryId) =>
                                            !validSubcategoryIds.includes(subCategoryId)
                                    );

                                    if (invalidSubcategories) {
                                        return createError({
                                            path: `${path}.${item.id}`, // Dynamic error path
                                            message:
                                                'vendor subcategory field must have at least 1 option selected',
                                        });
                                    }
                                }
                            }
                        }
                        return true; // Return `true` if no errors
                    }
                )
                .required(
                    i18n.t('Forms.Validation.FieldRequired', {
                        field: i18n.t('Forms.Labels.VendorSubCategory'),
                    })
                ),
            //         VendorSubCategoryIds : Yup.object()
            // .shape({
            //     dynamicKeys: Yup.lazy((value) =>
            //         Yup.object().shape(
            //             Object.keys(value || {}).reduce((acc, key) => {
            //                 if (/^\d+$/.test(key)) {
            //                     acc[key] = Yup.array().of(Yup.number()).nullable();
            //                 }
            //                 return acc;
            //             }, {})
            //         )
            //     ),
            // })
            // .label(i18n.t('Forms.Labels.VendorSubCategory'))
            // .nullable()
            // .test(
            //     'checkAtLeastOneSubcategory',
            //     i18n.t('Forms.Validation.VendorSubcategory'),
            //     (value) => {
            //         if (value) {
            //             // Iterate through each category's subcategories
            //             for (let key in value) {
            //                 // Check if the value is an empty array
            //                 if (Array.isArray(value[key]) && value[key].length === 0) {
            //                     // Return false (error) if the array is empty
            //                     return false;
            //                 }
            //             }
            //         }
            //         return true;  // Valid if no empty arrays found
            //     }
            // )
            // .test(
            //     'checkSubCategoriesBelongToCategory',
            //     'vendor subcategory field must have at least 1 option selected',
            //     async function (value, options) {
            //         console.log("@ value: ", value);
            //         console.log("@ options: ", options);
            //         const selectedWebstoreId = options.parent.VendorCategoryId;
            //         let hasErrors = false;
            //         console.log("@ selectedWebstoreId: ", selectedWebstoreId);

            //         if (selectedWebstoreId && value) {
            //             const category = categoryList.filter((x) =>
            //                 selectedWebstoreId
            //                     .split(',')
            //                     .map((item) => parseFloat(item.trim()))
            //                     .includes(x.id)
            //             );
            //             console.log("@ category: ", JSON.parse(JSON.stringify(category)));

            //             // Iterate over all the categories
            //             for (let item of category) {
            //                 if (item) {
            //                     console.log("@ item: ", JSON.parse(JSON.stringify(item)));
            //                     const validSubcategoryIds = item.subCategories.map((x) => x.id);

            //                     // Check if the subcategory array is empty for this category
            //                     console.log(" @ item.id, value[item.id].length: ", item.id , value[item.id]?.length);
            //                     if (value[item.id] && value[item.id].length === 0) {
            //                         // If array is empty, add a dynamic error for that category
            //                         this.createError({
            //                             path: `VendorSubCategoryIds.${item.id}`,  // Dynamic error path
            //                             message: i18n.t('Forms.Validation.SubcategoryEmpty', {
            //                                 category: item.label,
            //                             }),
            //                         });
            //                         console.log("@@ inside if ----");
            //                         hasErrors = true; // Track that there are errors
            //                     } else {
            //                         console.log("@@ inside else----")
            //                         // Check if any of the selected subcategories are invalid
            //                         const selectedSubcategories = value[item.id];
            //                         const invalidSubcategories = selectedSubcategories?.some(
            //                             (subCategoryId) => !validSubcategoryIds.includes(subCategoryId)
            //                         );
            //                         if (invalidSubcategories) {
            //                             console.log("@@ inside else if----")
            //                             hasErrors = true; // Track that there are errors
            //                         }
            //                     }
            //                 }
            //                 console.log("@ Item.id: error", item.id, hasErrors);
            //             }
            //         }

            //         return !hasErrors; // Return false if there are any errors, preventing form submission
            //     }
            // )
            // .required(
            //     i18n.t('Forms.Validation.FieldRequired', {
            //         field: i18n.t('Forms.Labels.VendorSubCategory'),
            //     })
            // ),
            Agree: Yup.bool().oneOf(
                [true],
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.SignupAgreement'),
                })
            ),
        })
        .required();

    return vendorBusinessDetailsFormSchema;
};

export { vendorBusinessDetailsFormSchemaWrapper };
