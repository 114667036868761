import styled from 'styled-components';
const CheckoutFormStyled = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    padding-right: 24px;
    @media (max-width: 815px) {
        width: 100%;
        padding-right: 16px;
    }

    .cardContainer {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 432px;
        @media (max-width: 815px) {
            padding-right: 0px;
            width: 100%;
        }
    }
    .card_input {
        width: 100%;
        font-size: 14px;
    }
    .CountryRegion-select {
        font-size: 14px;
        color: rgb(140, 140, 140);
    }
    .CountryRegion-select.is-filled {
        color: black !important;
    }

    .card {
        width: 100%;
        box-sizing: border-box;
        border: #ddd solid 1px;
        padding: 16px;
        outline: none;
        font-size: 14px;
        font-weight: light;
        height: 50px;
    }
    .StripeElement--complete {
        border-color: black;
    }
    .error-message {
        color: red;
        font-size: 12px;
        padding-top: 8px;
        padding-left: 16px;
    }

    .StripeElement--invalid {
        border-color: red;
    }
    .promoCodeText {
        color: var(--Basic-DBI-Black, #132122);
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: 'Objektiv Mk2';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        margin: 0px;
    }
    .promoCodeStyle {
        padding-left: 12px;
        padding-right: 8px;
        padding-top: 6px;
        padding-bottom: 6px;
        display: flex;
        border: 1px solid #c6c0cc;
        width: fit-content;
        height: fit-content;
        border-radius: 5px;
        gap: 8px;
    }
    .discountText {
        color: var(--Main-Brand-Basics-DBI-Black, #132122);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        margin-top: 8px;
    }
    .applyStyle {
        color: black;
        background-color: white;
        border: 1px solid black;
    }
    .toggle-switch-container {
        width: 34px;
    }
    .closeIconStyle {
        margin-top: 3px;
        margin-tight: 8px;
    }
    .promoCodeTextSection {
        display: flex;
        gap: 10px;
    }
    .promoCodeWithApply {
        display: flex;
        gap: 16px;
    }
    .promoCodeInputWidth {
        width: 330px;
    }
    .toggleSection {
        display: flex;
        gap: 8px;
    }
`;

export default CheckoutFormStyled;
