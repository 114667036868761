import styled from 'styled-components';

const EditPackagesPageStyled = styled.form`
    display: flex;
    flex-flow: column;
    h2 {
        text-transform: none;
    }

    .error {
        color: ${(props) => props.theme.colors.error.base};
        margin-bottom: 16px;
    }
`;

export { EditPackagesPageStyled };
