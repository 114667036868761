import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { PearlIcon, IconName, IconSizes, LinkButton } from 'dbi-pearl-ui-kit';
import { WebstoreFaqsStyled, StyledAccordion } from './WebstoreFaqs.styled';
import { SectionHeader } from '../SectionHeader/SectionHeader';

const defaultCount = 5;

const FaqAccordion = ({ title, body }) => {
    return (
        <StyledAccordion disableGutters square elevation={0}>
            <AccordionSummary
                expandIcon={<PearlIcon iconName={IconName.ARROW_DOWN} size={IconSizes.MD} />}
            >
                <h4>{title}</h4>
            </AccordionSummary>
            <AccordionDetails>
                <p className="p2">{body}</p>
            </AccordionDetails>
        </StyledAccordion>
    );
};

const WebstoreFaqs = (props) => {
    const { webstore, sectionRef, sectionId, iconName, banner } = props;
    const { t } = useTranslation();

    const [showAll, setShowAll] = useState(false);

    const buttonIcon = showAll ? 'minus' : 'plus';
    const buttonLabel = t(`WebstoreSections.Faqs.${showAll ? 'ViewLess' : 'ViewMore'}`);

    return (
        <WebstoreFaqsStyled
            ref={sectionRef}
            id={sectionId}
            showAll={showAll}
            defaultCount={defaultCount}
        >
            <SectionHeader
                title={t('WebstoreSections.Faqs.Title')}
                iconName={iconName}
                banner={banner}
                description={t('WebstoreSections.Faqs.Description')}
            />
            <div className="faqs-container">
                {webstore.Faqs?.map((faq) => (
                    <FaqAccordion key={faq.id} title={faq.question} body={faq.answer} />
                ))}
            </div>
            {webstore.Faqs?.length > defaultCount && (
                <div className="button-container">
                    <LinkButton
                        endIcon={buttonIcon}
                        label={buttonLabel}
                        onClick={() => setShowAll(!showAll)}
                        variant="accent"
                    >
                        {buttonLabel}
                    </LinkButton>
                </div>
            )}
        </WebstoreFaqsStyled>
    );
};

export { WebstoreFaqs };
