import styled from 'styled-components';

const EditAboutPageStyled = styled.form`
    display: flex;
    flex-flow: column;

    .section-header {
        .subtitle {
            margin-bottom: 0;
        }
    }
    .p4 {
        text-transform: capitalize;
    }
`;

export { EditAboutPageStyled };
