import { PearlApiClient } from 'framework';
import { getAppName } from 'shared/hooks/AppName';

export function StripeService() {
    const appName = getAppName();
    const api = PearlApiClient(appName);

    const createSubscription = async (
        paymentMethodId,
        name,
        priceId,
        email,
        vendorId,
        couponCode
    ) => {
        const res = await api.post('/create-subscription', {
            paymentMethod: paymentMethodId,
            name,
            priceId,
            email,
            vendorId,
            couponCode,
        });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const getStripeProducts = async () => {
        const res = await api.get('/get-stripe-products');
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const getPromocode = async (promoCodeId) => {
        const res = await api.get(`/getCoupons/${promoCodeId}`);
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    return {
        createSubscription,
        getStripeProducts,
        getPromocode,
    };
}
