import React from 'react';
import { PriceTierSection } from '../../PriceTierSection/PriceTierSectionNew';
import { PriceTierSectionEditStyled } from './PriceTierSectionEdit.styled';
import { SectionEditHeader } from '../SectionEditHeader/SectionEditHeader';

const PriceTierSectionEdit = ({ defaultTierValue }) => {
    return (
        <PriceTierSectionEditStyled data-testid="price-tier-section-edit">
            <SectionEditHeader
                title="Forms.PriceTier.StepName"
                description="Forms.PriceTier.EditSectionDescription"
            />
            <PriceTierSection defaultTierValue={defaultTierValue} />
        </PriceTierSectionEditStyled>
    );
};

export { PriceTierSectionEdit };
